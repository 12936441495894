import React from "react";
import axios from 'axios-jsonp-pro';
import * as Constant from '../../helpers/Constant.js';
import { useHistory } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
// import { stateToHTML } from "draft-js-export-html";

// components

export default function CardFormProduct({setAction, action, id}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [formValue, setformValue] = React.useState({
    title: '',
    subtitle: ''
  });
  const [formValidate, setFormValidate] = React.useState({
    validateTitle: false,
    validateSubtitle: false,
    validateDescription: false,
    title: '',
    subtitle: '',
    description: '',
  });
  const [description, setDescription] = React.useState(() => EditorState.createEmpty());
  const [descriptionString, setDescriptionString] = React.useState('');
  const [image, setImage] = React.useState(null);
  const [imagePreview, setImagePreview] = React.useState(null);

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
  }

  const handleChangeDescription = (event) => {
    setDescriptionString(event.blocks[0].text);
  }

  const onFileChange = (event) => {
    setImage(event.target.files[0]);
    setImagePreview(event.target.files.length > 0 ? URL.createObjectURL(event.target.files[0]) : '');
  }; 

  const emptyField = () => {
    setformValue({
      title: '',
      subtitle: ''
    });
    setDescription(EditorState.createEmpty());
    setImagePreview(null);
  }
  
  const history = useHistory();
  React.useEffect(() => {
    // console.log(action);
    const fetchData = async () => {
      return await axios.get(Constant.URL + '/product/show/' + id, {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('token')
        }
      })
      .then(res => {
        setformValue(res.data.data.product);
        setDescription(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(res.data.data.product.description)
            )
          )
        );
        setImagePreview(res.data.data.product.image);
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 401') {
          localStorage.clear();
          history.push("/admin");
        }
      });
    }
    action === 'Edit' ? fetchData() : emptyField();
  }, [action, id, history]);

  const handleStore = async (e) => {
    if (formValue.title === '') {
      setFormValidate({
        ...formValidate,
        validateTitle: true,
        title: 'Judul tidak boleh kosong!',
      });
      return false;
    }

    if (formValue.subtitle === '') {
      setFormValidate({
        ...formValidate,
        validateSubtitle: true,
        subtitle: 'Sub judul tidak boleh kosong!',
      });
      return false;
    }

    if (descriptionString === '') {
      setFormValidate({
        ...formValidate,
        validateDescription: true,
        description: 'Deskripsi tidak boleh kosong!',
      });
      return false;
    }
    
    e.preventDefault();
    setIsLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.append('title', formValue.title);
    bodyFormData.append('subtitle', formValue.subtitle);
    bodyFormData.append('description', descriptionString);
    bodyFormData.append('image', image); 
    
    return await axios.post(Constant.URL + '/product/store', bodyFormData, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('token')
      }
    })
    .then(res => {
    //   console.log(res);
      setIsLoading(false);
      setAction('list');
    })
    .catch(error => {
      setIsLoading(false);
      setShowAlert(true);
      setMessageError(error.message);
    });
  };

  const handleUpdate = async (e) => {
    if (formValue.title === '') {
      setFormValidate({
        ...formValidate,
        validateTitle: true,
        title: 'Judul tidak boleh kosong!',
      });
      return false;
    }

    if (formValue.subtitle === '') {
      setFormValidate({
        ...formValidate,
        validateSubtitle: true,
        subtitle: 'Sub judul tidak boleh kosong!',
      });
      return false;
    }

    if (descriptionString === '') {
      setFormValidate({
        ...formValidate,
        validateDescription: true,
        description: 'Deskripsi tidak boleh kosong!',
      });
      return false;
    }
    
    e.preventDefault();
    setIsLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.append('title', formValue.title);
    bodyFormData.append('subtitle', formValue.subtitle);
    bodyFormData.append('description', descriptionString);
    bodyFormData.append('image', image); 
    bodyFormData.append('_method', 'PUT');

    return await axios.post(Constant.URL + '/product/update/' + id, bodyFormData, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('token'),
      }
    })
    .then(res => {
      setIsLoading(false);
      setAction('list');
    })
    .catch(error => {
      setIsLoading(false);
      setShowAlert(true);
      setMessageError(error.message);
    });
  };

  return (
    <>
      {showAlert ? (
        <Dialog
          open={showAlert}
          onClose={() => setShowAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
          <DialogContent style={{minWidth: 300}}>
            <DialogContentText id="alert-dialog-description">
              {messageError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => setShowAlert(false)}
              className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
      ) : null}

      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Form Product {action}
            </h6>
            <div>
                <button
                  onClick={() => setAction('list')}
                  className="bg-red-500 text-white active:bg-res-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Back To List 
                </button>
                <button
                  onClick={(e) => action === 'add' ? handleStore(e) : handleUpdate(e)}
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  {!isLoading ? 'Submit' : <i className="fas fa-spin fa-spinner"></i>}
                </button>
            </div>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Content Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Title
                  </label>
                  {/* <input type="hidden" name="id" value={formValue.id}/> */}
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="title"
                    value={formValue.title}
                    required={true}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                  {
                    formValidate.validateTitle && formValue.title === '' ? <span style={{color: 'red', fontSize: 14}}>{formValidate.title}</span> : null
                  }
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Subtitle
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="subtitle"
                    value={formValue.subtitle}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                  {
                    formValidate.validateSubtitle && formValue.subtitle === '' ? <span style={{color: 'red', fontSize: 14}}>{formValidate.subtitle}</span> : null
                  }
                </div>
              </div>
            </div>
            
            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Description
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <div className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    <Editor
                      editorState={description}
                      // toolbarClassName="toolbarClassName"
                      // wrapperClassName="wrapperClassName"
                      // editorClassName="editorClassName"
                      onEditorStateChange={setDescription}
                      onChange={(e) => handleChangeDescription(e)}
                    />
                  </div>
                  {
                    formValidate.validateDescription && descriptionString === '' ? <span style={{color: 'red', fontSize: 14}}>{formValidate.description}</span> : null
                  }
                </div>
              </div>
            </div>

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Image
            </h6>
            <div className="text-center">
              <img src={action !== 'add' ? Constant.BASEURL + imagePreview : imagePreview} alt="image_preview" style={{margin: 'auto'}}/>
              <br></br>
              <input type="file" name="image" onChange={(e) => onFileChange(e)} className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
