import React, {useCallback} from "react";

// components

import CardFaq from "components/Cards/CardFaq.js";
import CardFormFaq from "components/Cards/CardFormFaq.js";

export default function Faq() {
  const [id, setId] = React.useState(null);
  const [action, setAction] = React.useState('list');

  const handleSetAction = useCallback((e) => {
    // const setAction = (e) => {
    //   return fetch("theURL", {method: "GET"}
    // )
    //   .then(res => normalizeResponseErrors(res))
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then(rcvdBusinesses => {
    //     // some stuff
    //   })
    //   .catch(err => {
    //     // some error handling
    //   });
    // };
    setAction(e);
  }, []);

  const handleSetId = useCallback((e) => {
    setId(e);
  }, []);

  // React.useEffect(() => {
  //   handleSetAction()
  // }, [handleSetAction])

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          {
            action === 'list' ? <CardFaq setAction={handleSetAction} id={id} setId={handleSetId} /> : <CardFormFaq setAction={handleSetAction} action={action} id={id} />
          }
        </div>
      </div>
    </>
  );
}
