import React, { useCallback }  from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios-jsonp-pro';
import PropTypes from "prop-types";
import * as Constant from '../../helpers/Constant.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from '@material-ui/core/Snackbar';

// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";

export default function CardFaq({ setAction, id, setId, color }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [showToast, setShowToast] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [refreshKey, setRefreshKey] = React.useState(0);

  const handleHideToast = () => {
    setShowToast(false);
  };

  const handleSetId = useCallback((e) => {
    setId(e);
  }, [setId]);

  const handleShowDialog = useCallback((e) => {
    setShowDialog(e);
  }, []);

  const handleIsEdit = useCallback((e) => {
    setAction(e)
  }, [setAction]);
  
  const history = useHistory();
  const [faq, setFaq] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      return await axios.get(Constant.URL + '/faq/index', {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('token'),
        }
      })
      .then(res => {
        setIsLoading(false);
        setFaq(res.data.data.faq); 
      })
      .catch(error => {
        setIsLoading(false);
        if (error.message === 'Request failed with status code 401') {
          localStorage.clear();
          history.push("/admin");
        }
      });
    }
    fetchData();
  }, [refreshKey, history]);

  const handleDestroy = async () => {
    return await axios.delete(Constant.URL + '/faq/destroy/' + id, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('token'),
      }
    })
    .then(res => {
      // console.log(res.data.data);
      setShowToast(true);
      // fetchData();
      setRefreshKey(oldKey => oldKey +1);
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        localStorage.clear();
        history.push("/admin");
      }
    });
  }

  const handlePublish = async (e) => {
    console.log(e);
    setShowToast(true);
  }
  
  return (
    <>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={showToast}
          autoHideDuration={1500}
          onClose={handleHideToast}
          message="Success Delete Data"
        />
      </div>

      {showDialog ? (
        <>
          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure want to delete this faq?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                onClick={() => setShowDialog(false)}
                className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                No
              </button>

              <button
                onClick={() => {
                  handleDestroy();
                  setShowDialog(false);
                }}
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Yes
              </button>
            </DialogActions>
          </Dialog>
        </>
      ) : null};

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex justify-between">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                FAQ
              </h3>

              <button
                onClick={() => {
                  setAction('add');
                }}
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">

          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Question
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Answer
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Publish
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  <>
                    <tr>
                      <td colSpan="6" className="text-center">
                        <br></br>
                        <i className="fas fa-spin fa-spinner"></i>
                        <br></br>
                        <br></br>
                      </td>
                    </tr>
                  </>
                ) : faq.length < 1 ? (
                  <>
                    <tr>
                        <td colSpan="6" className="text-center border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <br></br>
                          <span className={
                            "ml-3 text-blueGray-600"
                          }>No data available!</span>
                          <br></br>
                          <br></br>
                        </td>
                      </tr>
                  </>
                ) : faq.map(( key, index ) => {
                  return (
                    <tr>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <span
                          className={
                            "ml-3 font-bold text-blueGray-600"
                          }
                        >
                          {key.question}
                      </span>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {key.answer}
                      </td>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <input 
                            type="checkbox"
                            className="bg-gold form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                            defaultChecked={key.publish}
                            onChange={() => handlePublish(key.publish)}
                          />
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                        <TableDropdown id={key.id} setId={handleSetId} setShowDialog={handleShowDialog} setIsEdit={handleIsEdit} />
                      </td>
                    </tr>
                  );
                })
              }
                
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardFaq.defaultProps = {
  color: "light",
};

CardFaq.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
