import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import validator from 'validator';

import OtpInput from 'react-otp-input';

import { Switch, Route, Redirect, withRouter, useHistory } from "react-router-dom";
import axios from 'axios-jsonp-pro';
import * as Constant from '../helpers/Constant.js';

import MobileAuthNavbar from "components/Navbars/MobileAuthNavbar.js";

// views
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

export default function AuthMember() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [showToast, setShowToast] = React.useState(false);
  const [messageToast, setMessageToast] = React.useState('Nomor handphone tidak boleh kosong!');
  const [formValue, setformValue] = React.useState({
    handphone: '',
    name: '',
    email: '',
    emailOrPhone: ''
  });
  const [token, setToken] = React.useState('');
  const [disableButton, setDisableButton] = React.useState(false);
  const [disableButtonResend, setDisableButtonResend] = React.useState(true);

  const [page, setPage] = React.useState('login');

  const [otp, setOtp] = React.useState('');
  const changeOtp = (value) => {
    setOtp(value);
  }

  const handleHideToast = () => {
    setShowToast(false);
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    })
  }

  const [validEmail, setValidEmail] = React.useState(false);
  const validateEmail = (event) => {
    if (validator.isEmail(event.target.value)) {
      setValidEmail(true);
      
      setformValue({
        ...formValue,
        'email' : event.target.value
      });
      console.log(formValue.email);
    } else {
      setValidEmail(false);
      
      setformValue({
        ...formValue,
        'handphone' : event.target.value
      });
      console.log(formValue.handphone);
    }
  }

  const history = useHistory();
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoadingOtp(true);

    if (otp === '') {
      setIsLoadingOtp(false);
      setMessageToast('Kode otp tidak boleh kosong!');
      setShowToast(true);
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append('handphone', formValue.handphone);
      bodyFormData.append('otp', otp);
      
      return await axios.post(Constant.URL + '/otp-verification', bodyFormData)
      .then(res => {
        // console.log(res.data);
        setIsLoadingOtp(false);
        if (res.data.message === '') {
          setMessageToast('Kode otp salah!');
          setShowToast(true);
        } else {
          localStorage.setItem('tokenMember', token);
          history.push("/member");
        }
      })
      .catch(error => {
        setIsLoadingOtp(false);
        if (error.message === 'Request failed with status code 402') {
          setShowAlert(true);
          setMessageError('Kode Otp Salah!');
        } else {
          setShowAlert(true);
          setMessageError(error.message);
        }
      });
    }
  };

  const goToOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (formValue.handphone === '') {
      setIsLoading(false);
      setMessageToast('Nomor handphone tidak boleh kosong!');
      setShowToast(true);
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append('handphone', formValue.handphone);
      
      return await axios.post(Constant.URL + '/login-member', bodyFormData)
      .then(res => {
        setIsLoading(false);
        if (res.data.message === 'Member not found') {
          setPage('register');
        } else {
          // console.log(res.data.token);
          setDisableButton(false);
          setDisableButtonResend(true);
          setMinutes(1);
          setSeconds(59);
          setToken(res.data.token);
          setPage('otp');
        }
      })
      .catch(error => {
        setIsLoading(false);
        setShowAlert(true);
        setMessageError(error.message);
      });
    }
  };

  const loginLakuemas = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (validEmail ? formValue.email : formValue.handphone === '') {
      setIsLoading(false);
      setMessageToast('Email atau nomor handphone tidak boleh kosong!');
      setShowToast(true);
    } else {
      const bodyFormData = new FormData();

      validEmail ? bodyFormData.append('email', formValue.email) : bodyFormData.append('handphone', formValue.handphone);
      bodyFormData.append('password', formValue.password);
      
      return await axios.post(Constant.URL + '/login-member-with-password', bodyFormData)
      .then(res => {
        setIsLoading(false);
        if (res.data.message === 'Member not found') {
          setMessageToast('Email, nomor handphone atau password salah!');
          setShowToast(true);
        } else {
          // console.log(res);
          setDisableButton(false);
          setDisableButtonResend(true);
          setMinutes(1);
          setSeconds(59);
          setToken(res.data.token);
          setPage('otp');
        }
      })
      .catch(error => {
        setIsLoading(false);
        setShowAlert(true);
        setMessageError(error.message);
      });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (formValue.name === '') {
      setIsLoading(false);
      setMessageToast('Nama tidak boleh kosong!');
      setShowToast(true);
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append('name', formValue.name);
      bodyFormData.append('email', formValue.email);
      bodyFormData.append('handphone', formValue.handphone);
      
      return await axios.post(Constant.URL + '/register-member', bodyFormData)
      .then(res => {
        // console.log(res.data);
        setIsLoading(false);
        setDisableButton(false);
        setDisableButtonResend(true);
        setMinutes(1);
        setSeconds(59);
        setToken(res.data.token);
        setPage('otp');
      })
      .catch(error => {
        setIsLoading(false);
        setShowAlert(true);
        setMessageError(error.message);
      });
    }
  };

  const goToLogin = async (e) => {
    e.preventDefault();
    setPage('login');
  };

  const goToLoginLakuemas = async (e) => {
    e.preventDefault();
    setPage('loginLakuemas');
  };

  const [minutes, setMinutes ] = React.useState(0);
  const [seconds, setSeconds ] =  React.useState(59);

  React.useEffect(() => {
    // counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    const myInterval = setInterval(() => {
      if (seconds > 0) {
          setSeconds(seconds - 1);
      }
      if (seconds === 0) {
          if (minutes === 0) {
              clearInterval(myInterval)
          } else {
              setMinutes(minutes - 1);
              setSeconds(59);
          }
      } 

      if (seconds === 0 && minutes === 0) {
        setDisableButton(true);
        setDisableButtonResend(false);
      }
    }, 1000)
    return ()=> {
      clearInterval(myInterval);
    };
  }, [seconds, minutes]);

  const switchPage = () => {
    switch(page) {
      case 'login':
        return (
          <>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-10">
              <div className="text-center mb-4">
                <label className="inline-flex items-center">
                  <span className="ml-2 text-sm font-semibold text-blueGray-600">
                    Masukkan nomor handphone untuk login atau registrasi
                  </span>
                </label>
              </div>

              <hr></hr>
              
              <form>
                <div className="relative w-full mb-3 mt-6">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nomor HP
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="number"
                    name="handphone"
                    value={formValue.handphone}
                    className="border-2 border-gold px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Nomor HP"
                  />
                </div>

                {showAlert ? (
                  <Dialog
                    open={showAlert}
                    onClose={() => setShowAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                    <DialogContent style={{minWidth: 300}}>
                      <DialogContentText id="alert-dialog-description">
                        {messageError}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setShowAlert(false)} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : null}

                <div className="text-center mt-6">
                  <button
                    onClick={(e) => goToOtp(e)}
                    className="bg-gold text-white active:bg-gold text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                  >
                    {!isLoading ? 'Lanjutkan' : <i className="fas fa-spin fa-spinner"></i>}
                  </button>
                </div>
                
                <div className="text-center mt-2">
                  <label className="inline-flex items-center">
                    <span className="ml-2 text-sm font-semibold text-blueGray-600">
                      Atau
                    </span>
                  </label>
                </div>

                <div className="text-center mt-4">
                  <button
                    onClick={(e) => goToLoginLakuemas(e)}
                    className="border-2 item-center border-gold bg-white text-gold active:bg-gold text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap'}}
                  >
                    <img
                      alt="invest"
                      src={require("assets/img/icon/logo_lakuemas_color.png").default}
                      style={{width: 20, marginRight: 10}}
                    /> 
                    Login dengan akun Lakuemas
                  </button>
                </div>
              </form>
            </div>
          </>
        );
      case 'otp':
        return (
          <>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-10">
              <div className="text-center mb-4">
                <label className="inline-flex items-center">
                  <span className="ml-2 text-sm font-semibold text-blueGray-600">
                    Masukkan kode OTP yang telah kami kirimkan ke nomor {formValue.handphone}
                  </span>
                </label>
              </div>

              <hr></hr>
              
              <form>
                <label
                  className="block text-blueGray-600 text-xs font-bold mb-2 pt-6"
                  htmlFor="grid-password"
                >
                  Kode OTP
                </label>
                <div className="ml-3">
                  <OtpInput
                    value={otp}
                    onChange={changeOtp}
                    numInputs={6}
                    isInputNum={true}
                    separator={<span> </span>}
                    inputStyle={"border-2 border-gold rounded mr-4 w-1/2 py-3"}
                  />
                </div>

                <div className="text-center mt-2">
                  <label className="inline-flex items-center">
                    <span className="text-sm font-semibold text-blueGray-600">
                    { minutes === 0 && seconds === 0
                        ? null
                        : <h1> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1> 
                    }
                    </span>
                  </label>
                </div>

                {showAlert ? (
                  <Dialog
                    open={showAlert}
                    onClose={() => setShowAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                    <DialogContent style={{minWidth: 300}}>
                      <DialogContentText id="alert-dialog-description">
                        {messageError}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setShowAlert(false)} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : null}

                <div className="text-center mt-6">
                  <button
                    disabled={disableButton}
                    onClick={(e) => handleLogin(e)}
                    className="bg-gold text-white active:bg-gold text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                  >
                    {!isLoadingOtp ? 'Lanjutkan' : <i className="fas fa-spin fa-spinner"></i>}
                  </button>
                </div>

                <div className="text-center mt-4">
                  <button
                    disabled={disableButtonResend}
                    onClick={(e) => goToOtp(e)}
                    className="border-2 item-center border-gold bg-white text-gold active:bg-gold text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap'}}
                  >
                    {!isLoading ? 'Kirim Ulang' : <i className="fas fa-spin fa-spinner"></i>}
                  </button>
                </div>
              </form>
            </div>
          </>
        );
      case 'loginLakuemas':
        return (
          <>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-10">
              <div className="text-center item-center mb-4">
                  <img
                    alt="invest"
                    src={require("assets/img/icon/logo_lakuemas_color.png").default}
                    style={{width: 40, margin: '0 auto'}}
                  /> 
                <label className="inline-flex items-center">
                  <span className="ml-2 text-sm font-semibold text-blueGray-600">
                    Login Akun Lakuemas
                  </span>
                </label>
              </div>

              <hr></hr>
              
              <form>
                <div className="relative w-full mb-3 mt-6">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email / Nomor HP
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => validateEmail(e)}
                    type="text"
                    name="emailOrPhone"
                    value={formValue.emailOrPhone}
                    className="border-2 border-gold px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email / Nomor HP"
                  />
                </div>
                {validEmail}

                <div className="relative w-full mb-3">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="password"
                    name="password"
                    value={formValue.password}
                    className="border-2 border-gold py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                  />
                </div>

                {showAlert ? (
                  <Dialog
                    open={showAlert}
                    onClose={() => setShowAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                    <DialogContent style={{minWidth: 300}}>
                      <DialogContentText id="alert-dialog-description">
                        {messageError}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setShowAlert(false)} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : null}

                <div className="text-center mt-6">
                  <button
                    onClick={(e) => loginLakuemas(e)}
                    className="bg-gold text-white active:bg-gold text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                  >
                    {!isLoading ? 'Lanjutkan' : <i className="fas fa-spin fa-spinner"></i>}
                  </button>
                </div>

                <div className="text-center mt-4">
                  <button
                    onClick={(e) => goToLogin(e)}
                    className="border-2 item-center border-gold bg-white text-gold active:bg-gold text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap'}}
                  >
                    Kembali
                  </button>
                </div>
              </form>
            </div>
          </>
        );
      case 'register':
        return (
          <>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-10">
              <div className="text-center item-center mb-4">
                <label className="inline-flex items-center">
                  <span className="ml-2 text-sm font-semibold text-blueGray-600">
                    Nomor Anda belum terdaftar. Silakan masukan nama lengkap dan email untuk registrasi akun Laku Gadai.
                  </span>
                </label>
              </div>

              <hr></hr>
              
              <form>
                <div className="relative w-full mb-3 mt-6">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nama Lengkap
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="name"
                    value={formValue.name}
                    className="border-2 border-gold px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Nama Lengkap"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="email"
                    name="email"
                    value={formValue.email}
                    className="border-2 border-gold py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>

                {showAlert ? (
                  <Dialog
                    open={showAlert}
                    onClose={() => setShowAlert(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                    <DialogContent style={{minWidth: 300}}>
                      <DialogContentText id="alert-dialog-description">
                        {messageError}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setShowAlert(false)} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                ) : null}

                <div className="text-center mt-6">
                  <button
                    onClick={(e) => handleRegister(e)}
                    className="bg-gold text-white active:bg-gold text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                  >
                    {!isLoading ? 'Lanjutkan' : <i className="fas fa-spin fa-spinner"></i>}
                  </button>
                </div>
                
                <div className="text-center mt-4">
                  <button
                    onClick={(e) => goToLogin(e)}
                    className="border-2 item-center border-gold bg-white text-gold active:bg-gold text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap'}}
                  >
                    Kembali
                  </button>
                </div>
              </form>
            </div>
          </>
        );
      default:
        return '0';
    }
  }

  return (
    <>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={showToast}
          autoHideDuration={2000}
          onClose={handleHideToast}
          message={messageToast}
        />
      </div>

      <main>
        <section className="w-full h-full bg-blueGray-200">

            <div className="container-mobile mx-auto h-full h-auto bg-white shadow-lg bg-center bg-cover" style={{
              backgroundImage:
                "url(" + require("assets/img/login_bg.png").default + ")",
            }}>

              <MobileAuthNavbar />

              <div className="flex pt-16 content-center justify-center h-full h-auto px-4 pb-20">
              
                <div className="w-full lg:w-12/12 px-4">

                  <img
                    alt="invest"
                    src={require("assets/img/icon/login.png").default}
                    style={{margin: 'auto'}}
                  />

                  <div className="text-center mb-4">
                    <label className="inline-flex items-center">
                      <span className="ml-2 text-sm font-bold text-base">
                        Member Area
                      </span>
                    </label>
                  </div>

                  <div className="relative border-1 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                    {switchPage()}
                  </div>

                </div>
              </div>
              
            </div>

          <Switch>
            <Route path="/auth/login" exact component={withRouter(Login)} />
            <Route path="/auth/register" exact component={withRouter(Register)} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          {/* <FooterSmall absolute /> */}
        </section>
      </main>
    </>
  );
}
