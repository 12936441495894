import React from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios-jsonp-pro';
import * as Constant from '../../helpers/Constant.js';

// components

export default function CardSettings() {
  // const [isLoading, setIsLoading] = React.useState(true);
  // const [showToast, setShowToast] = React.useState(false);
  // const [toastMessage, setToastMessage] = React.useState('');
  // const [showDialog, setShowDialog] = React.useState(false);

  const history = useHistory();
  const [setting, setSetting] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      return await axios.get(Constant.URL + '/setting/index', {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('token'),
        }
      })
      .then(res => {
        console.log(res.data.data.setting);
        // setIsLoading(false);
        setSetting(res.data.data.setting); 
      })
      .catch(error => {
        // setIsLoading(false);
        if (error.message === 'Request failed with status code 401') {
          localStorage.clear();
          history.push("/admin");
        }
      });
    }
    fetchData();
  }, [history]);
  

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">Setting</h6>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Update
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              General Setting
            </h6>
            <div className="flex flex-wrap">

              {
                setting.map(( key, index ) => {
                  return (
                    <div key={index} className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          {key.type}
                        </label>
                        <input
                          type="text"
                          value={key.value}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                      </div>
                    </div>
                  );
                })
              }
            

            </div>
            
          </form>
        </div>
      </div>
    </>
  );
}
