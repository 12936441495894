import React from "react";
import { Link, useHistory } from "react-router-dom";

// components

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Partner from '../components/Section/SectionPartner';

const Accordion = withStyles({
  root: {
    backgroundColor: '#E2E8F0',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    borderRadius: 30,
    backgroundColor: 'rgba(255, 255, 255)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: 20,
    minHeight: 56,
    '&$expanded': {
      backgroundColor: '#FFB200',
      color: 'white',
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: '#E2E8F0',
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    flexBasis: '100%',
    flexShrink: 0,
    fontWeight: 'bold'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function GoldTunai() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const history = useHistory();
  const handleOnClick = (e) => {
    localStorage.setItem('section', e);
    history.push('/');
  };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <AuthNavbar fixed />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url(" + require("assets/img/gold_tunai.png").default + ")",
            }}
          >
            <span
              // id="blackOverlay"
              className="w-full h-full absolute"
            ></span>
          </div>
      
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">

              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto mt-16">
                <div style={{padding: 30, backgroundColor: 'rgb(20, 19, 19, 0.5)', borderRadius: 10}}>
                  <h1 className="text-white font-semibold text-5xl">
                    Gold Tunai
                  </h1>
                  <hr className="mt-8"></hr>
                  <p className="mt-4 text-lg text-blueGray-200">
                    Gold tunai merupakan produk pinjaman dengan sistem gadai dengan barang jaminan berupa emas maupun berlian untuk memenuhi kebutuhan pelanggan memperoleh dana dengan cepat.
                  </p>
                  <br></br>
                  <Link
                    to="/simulation/0"
                    className="bg-gold text-white active:bg-gold text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    Simulasi Gadai Emas
                  </Link>
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                </div>
              </div>

            </div>
          </div>

        </div>

        <section className="pb-20 pt-12 bg-blueGray-200">
          <div className="container mx-auto px-4">

            <div className="w-full lg:w-12/12">
              <img
                alt="gold-tunai"
                src={require("assets/img/icon/gold_tunai.png").default}
                style={{margin: 'auto'}}
              />
            </div>

            <div className="flex flex-wrap items-center mt-12">
              <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">

                <div className="w-full md:w-12/12 px-4">
                  <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex">
                      <div className="md:w-2/12">
                        <img
                          alt="fast-clock"
                          src={require("assets/img/icon/fast_clock.png").default}
                        />
                      </div>
                      <div className="md:w-10/12 px-4">
                        <h6 className="text-xl font-semibold">Proses Mudah dan Cepat</h6>
                        <p className="mt-2 mb-4 text-blueGray-500">
                          Cukup membawa KTP dan tidak ada penilaian kredit
                          <br></br>
                          <br></br>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-12/12 px-4">
                  <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex">
                      <div className="md:w-2/12">
                        <img
                          alt="thumb-up"
                          src={require("assets/img/icon/thumb_up.png").default}
                        />
                      </div>
                      <div className="md:w-10/12 px-4">
                        <h6 className="text-xl font-semibold">Layanan Terbaik</h6>
                        <p className="mt-2 mb-4 text-blueGray-500">
                          Pelayanan prima yang mengutamakan kepuasan pelanggan
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-12/12 px-4">
                  <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex">
                      <div className="md:w-2/12">
                        <img
                          alt="shield-check"
                          src={require("assets/img/icon/shield_check.png").default}
                          style={{width: 70}}
                        />
                      </div>
                      <div className="md:w-10/12 px-4">
                        <h6 className="text-xl font-semibold">Keamanan Terjamin</h6>
                        <p className="mt-2 mb-4 text-blueGray-500">
                          Barang jaminan Anda disimpan dengan aman dan telah diasuransikan
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>

              <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">
                
                <div className="w-full md:w-12/12 px-4">
                  <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex">
                      <div className="md:w-2/12">
                        <img
                          alt="discount-bag"
                          src={require("assets/img/icon/discount_bag.png").default}
                          style={{width: 70}}
                        />
                      </div>
                      <div className="md:w-10/12 px-4">
                        <h6 className="text-xl font-semibold">Nilai Pinjaman yang Besar</h6>
                        <p className="mt-2 mb-4 text-blueGray-500">
                          Kami akan berikan nilai pinjaman yang besar untuk emas dan perhiasan Anda
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-12/12 px-4">
                  <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex">
                      <div className="md:w-2/12">
                        <img
                          alt="interest-rate"
                          src={require("assets/img/icon/interest_rate.png").default}
                          style={{width: 70}}
                        />
                      </div>
                      <div className="md:w-10/12 px-4">
                        <h6 className="text-xl font-semibold">Biaya Sewa Modal Terjangkau</h6>
                        <p className="mt-2 mb-4 text-blueGray-500">
                          Cukup membayar sewa modal per 15 hari dan tidak dikenakan biaya lain
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-12/12 px-4">
                  <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex">
                      <div className="md:w-2/12">
                        <img
                          alt="fast-clock"
                          src={require("assets/img/icon/fast_clock.png").default}
                          style={{width: 70}}
                        />
                      </div>
                      <div className="md:w-10/12 px-4">
                        <h6 className="text-xl font-semibold">Jangka Waktu Pinjaman yang Fleksibel</h6>
                        <p className="mt-2 mb-4 text-blueGray-500">
                          Pilihan tenor yang beragam dan fleksibel menyesuaikan dengan kebutuhan Anda
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">

          <div className="container mx-auto px-4">

            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-12/12 px-4">
                <img
                  alt="term-and-condition"
                  src={require("assets/img/icon/term_and_condition.png").default}
                  style={{margin: 'auto'}}
                />
                <h2 className="text-4xl font-semibold">Syarat Pengajuan Gold Tunai</h2>
              </div>
            </div>

            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-6/12 ml-auto mr-auto">
                <img
                  alt="tnc"
                  className="max-w-full rounded-lg shadow-lg"
                  src={require("assets/img/tnc.png").default}
                />
              </div>
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default}
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Merupakan warga negara Indonesia dan memiliki Kartu Tanda Penduduk (KTP)
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Telah berusia 17 tahun
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Membawa barang jaminan dan dokumen yang menyertai seperti invoice dan sertifikat
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Barang jaminan yang diterima adalah perhiasan emas mulai dari 16 karat keatas, perhiasan berlian dan logam mulia batangan.
                          </h4>
                        </div>
                      </div>
                    </li>

                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Jangka waktu pinjaman adalah 120 hari dan dapat diperpanjang dengan skema gadai uang.
                          </h4>
                        </div>
                      </div>
                    </li>

                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Biaya sewa modal adalah 6% per 120 hari
                          </h4>
                        </div>
                      </div>
                    </li>

                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Menyetujui syarat dan ketentuan yang berlaku
                          </h4>
                        </div>
                      </div>
                    </li>


                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-blueGray-800 py-20">

          <div className="container mx-auto px-4">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Mekanisme Persyaratan Gadai
                </h2>
              </div>
            </div>

            <div className="flex flex-wrap mt-12 justify-center">
              
              <div className="w-full lg:w-6/12 px-4">
                <div className="flex min-w-0 break-words bg-gold w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex">
                    <div className="md:w-112/12 px-4">
                      <h6 className="text-xl font-semibold text-white">PERSYARATAN PENCAIRAN</h6>

                      {/* <div className="w-full md:w-12/12">
                        <div className="flex min-w-0 break-words w-full mb-8">
                          <div className="px-4 flex">
                            <div className="md:w-2/12">
                              <img
                                alt="identification-card"
                                src={require("assets/img/icon/identification_card.png").default}
                                style={{width: 50}}
                              />
                            </div>
                            <div className="md:w-10/12 px-4">
                              <p className="mt-2 mb-4 text-white">
                                1. Fotocopy KTP atau identitas lainnya
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full md:w-12/12">
                        <div className="flex min-w-0 break-words w-full mb-8">
                          <div className="px-4 flex">
                            <div className="md:w-2/12">
                              <img
                                alt="give-diamond"
                                src={require("assets/img/icon/give_diamond.png").default}
                                style={{width: 50}}
                              />
                            </div>
                            <div className="md:w-10/12 px-4">
                              <p className="mt-2 mb-4 text-white">
                                2. Menyerahkan barang jaminan
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      
                      <ul className="list-none">
                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <img
                                alt="identification-card"
                                src={require("assets/img/icon/identification_card.png").default}
                                // className="rounded-full mx-auto max-w-120-px"
                              />
                            </div>
                            <div>
                              <h4 className="text-white pl-4">
                                1. Fotocopy KTP atau identitas lainnya
                              </h4>
                            </div>
                          </div>
                        </li>

                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <img
                                alt="give-diamond"
                                src={require("assets/img/icon/give_diamond.png").default}
                                // className="rounded-full mx-auto max-w-120-px"
                              />
                            </div>
                            <div>
                              <h4 className="text-white pl-4">
                                2. Menyerahkan barang jaminan
                              </h4>
                            </div>
                          </div>
                        </li>

                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <img
                                alt="form-document"
                                src={require("assets/img/icon/form_document.png").default}
                                // className="rounded-full mx-auto max-w-120-px"
                              />
                            </div>
                            <div>
                              <h4 className="text-white pl-4">
                                3. Mengisi formulir permohonan gadai
                              </h4>
                            </div>
                          </div>
                        </li>

                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <img
                                alt="sign-contract"
                                src={require("assets/img/icon/sign_contract.png").default}
                                // className="rounded-full mx-auto max-w-120-px"
                              />
                            </div>
                            <div>
                              <h4 className="text-white pl-4">
                                4. Nasabah menandatangani Surat Bukti Gadai (SBG)
                              </h4>
                            </div>
                          </div>
                        </li>

                      </ul>
                    
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="flex min-w-0 break-words bg-gold w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex">
                    <div className="md:w-112/12 px-4">
                      <h6 className="text-xl font-semibold text-white">PERSYARATAN GADAI ULANG</h6>
                      <ul className="list-none">
                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <img
                                alt="identification-card"
                                src={require("assets/img/icon/identification_card.png").default}
                                // className="rounded-full mx-auto max-w-120-px"
                              />
                            </div>
                            <div>
                              <h4 className="text-white pl-4">
                                1. Fotocopy KTP atau identitas lainnya
                              </h4>
                            </div>
                          </div>
                        </li>

                        <li className="py-2">
                          <div className="flex items-center">
                            <div>
                              <img
                                alt="give-diamond"
                                src={require("assets/img/icon/give_diamond.png").default}
                                // className="rounded-full mx-auto max-w-120-px"
                              />
                            </div>
                            <div>
                              <h4 className="text-white pl-4">
                                2. Menyiapkan dana untuk gadai ulang
                              </h4>
                            </div>
                          </div>
                        </li>

                        <li className="py-2">
                          <div className="flex items-center">
                            <div style={{ marginRight: 10}}>
                              <img
                                alt="form-document"
                                src={require("assets/img/icon/form_document.png").default}
                                // className="rounded-full mx-auto max-w-120-px"
                              />
                            </div>
                            <div>
                              <h4 className="text-white pl-4">
                                3. Nasabah membawa Surat Bukti Gadai (SBG) yang lama untuk diganti dengan yang baru
                              </h4>
                            </div>
                          </div>
                        </li>

                      </ul>
                      <br></br>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>


        <section className="relative py-20 bg-blueGray-200">

          <div className="container mx-auto px-4">

            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-12/12 px-4">
                <img
                  alt="term-and-condition"
                  src={require("assets/img/icon/term_and_condition.png").default}
                  style={{margin: 'auto'}}
                />
                <h2 className="text-4xl font-semibold">Paling Sering Ditanyakan</h2>
              </div>
            </div>

            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-6/12 ml-auto mr-auto">
                <img
                  alt="tnc-2"
                  className="max-w-full rounded-lg shadow-lg"
                  src={require("assets/img/tnc_2.png").default}
                />
              </div>
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">


                  <div className={classes.root}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="bg-gold"
                      >
                        <Typography className={classes.heading}>
                          Apakah pinjaman bisa di perpanjang?
                        </Typography> 
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Iya, perpanjangan dengan skema gadai ulang anda diharuskan untuk membayar sewa modal dan biaya administrasi
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography className={classes.heading}>
                          Apakah perpanjangan bisa dilakukan kurang dari 4 bulan?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Bisa, perpanjangan bisa dilakukan per 15 hari
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                      >
                        <Typography className={classes.heading}>
                          Jika pelanggan membayar lebih cepat apakah sewa modal akan dipotong?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Tidak bisa, karena pembayaran sewa modal telah dipotong diawal
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography className={classes.heading}>
                          Apabila invoice hilang, apakah pelanggan dapat mengikuti Gold Tunai?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Iya, dengan melengkapi Surat Keterangan Hilang dari Kepolisian dan mengisi surat pernyataan kepemilikan barang
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                      >
                        <Typography className={classes.heading}>
                          Apakah perhiasan yang kurang lengkap, cacat, atau rusak dapat dijadikan sebagai barang jaminan?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Tidak akan diterima perhiasan yang kurang lengkap, rusak , atau cacat
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-16 bg-white relative pt-32">

          <div className="container mx-auto">
            <div className="flex flex-wrap justify-center bg-white rounded-lg px-12 relative z-10">
              <div className="w-full text-center lg:w-8/12">
                <h3 className="font-semibold text-3xl">
                  Yuk, gadaikan emas Anda sekarang!
                </h3>
                <div className="sm:block flex flex-col mt-10">
                  <Link
                    to="/simulation/0"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-gold active:bg-gold uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Simulasi Gadai Emas
                  </Link>
                  <Link
                    // to="/"
                    className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gold active:bg-gold uppercase text-sm shadow hover:shadow-lg"
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnClick('contact-section');
                    }}
                  >
                    <span>Hubungi Kami</span>
                  </Link>
                </div>
                <div className="text-center mt-16"></div>
              </div>
            </div>
          </div>
        </section>
        
        <Partner />
      </main>
      <Footer />
    </>
  );
}
