import React from "react";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios-jsonp-pro';
import * as Constant from '../../helpers/Constant.js';

export default function Profile() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [member, setMember] = React.useState({});

  const history = useHistory();
  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      return await axios.get(Constant.URL + '/profile-member', {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('tokenMember'),
        }
      })
      .then(res => {
        setIsLoading(false);
        setMember(res.data.data.member);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.message === 'Request failed with status code 401') {
          localStorage.clear();
          history.push("/member");
        }
      });
    }
    fetchData();
  }, [history]);
  
  const handleLogout = async () => {
    // e.preventDefault();
    
    setIsLoading(true);
    setTimeout(
      () => {
        // localStorage.setItem('tokenMember', null);
        setIsLoading(false);
        localStorage.clear();
        history.push("/member");
      },
      2000
    );
  };

  return (
    <>
      <div className="flex pt-16 h-full h-auto pb-20">
              
        <div className="w-full lg:w-12/12 px-4">

          <div className="w-full mb-6 bg-white px-4 py-4 shadow-lg rounded-lg mt-3">
            <div className="mb-1">
              <label className="inline-flex items-center mb-3">
                <span className="ml-2 text-lg font-bold">
                  {member.name}
                </span>
              </label>
              <hr></hr>
              <label className="inline-flex items-center mt-3">
                <span className="ml-2 text-sm text-base">
                  {member.email}
                </span>
              </label>
              <br></br>
              <label className="inline-flex items-center">
                <span className="ml-2 text-sm text-base">
                  {member.handphone}
                </span>
              </label>
            </div>
          </div>

          <div className="w-full">
            <label className="inline-flex items-center">
              <span className="ml-2 text-lg font-bold">
                Akun
              </span>
            </label>
          </div>

          <div className="w-full mb-3 bg-white px-4 py-1 shadow-lg rounded-lg mt-3">
            <Link className="mx-auto flex flex-wrap items-center justify-between mt-2 mb-2">
              <h4 className="text-blueGray-500 ml-2">
                <b>Data KTP</b>
              </h4>
              <div
                className="flex flex-grow items-center bg-opacity-0 shadow-none block  list-none ml-auto"
              >
                <ul className="flex flex-row list-none ml-auto">
                  <li>
                    <div className="w-full mb-1 bg-green text-sm px-4 py-2 rounded-lg mt-3">
                      Verifikasi Berhasil
                    </div>
                  </li>
                  <li className="flex items-center">
                    <Link
                      className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      target="_blank"
                    >
                      <img
                        alt="chevron_right"
                        src={require("assets/img/icon/chevron_right.png").default}
                        style={{width: 30}}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </Link>
            <hr></hr>

            <Link className="mx-auto flex flex-wrap items-center justify-between mt-2 mb-2">
              <h4 className="text-blueGray-500 ml-2">
                <b>Data Rekening Bank</b>
              </h4>
              <div
                className="flex flex-grow items-center bg-opacity-0 shadow-none block list-none ml-auto"
              >
                <ul className="flex flex-col lg:flex-row list-none ml-auto">

                  <li className="flex items-center">
                    <Link
                      className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      target="_blank"
                    >
                      <img
                        alt="chevron_right"
                        src={require("assets/img/icon/chevron_right.png").default}
                        style={{width: 30}}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </Link>
          </div>

          <div className="w-full mb-3 bg-white px-4 py-1 shadow-lg rounded-lg mt-3">
            <Link className="mx-auto flex flex-wrap items-center justify-between mt-2 mb-2">
              <h4 className="text-blueGray-500 ml-2">
                <b>Hubungi Kami</b>
              </h4>
              <div
                className="flex flex-grow items-center bg-opacity-0 shadow-none block  list-none ml-auto"
              >
                <ul className="flex flex-col lg:flex-row list-none ml-auto">

                  <li className="flex items-center">
                    <Link
                      className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      target="_blank"
                    >
                      <img
                        alt="chevron_right"
                        src={require("assets/img/icon/chevron_right.png").default}
                        style={{width: 30}}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </Link>
          </div>

          <div className="w-full mb-3 bg-white px-4 py-1 shadow-lg rounded-lg mt-3">
            <button 
              onClick={() => handleLogout()}
              className="w-full outline-none focus:outline-none ease-linear transition-all duration-150"
            >
              <div
                className="w-full mx-auto flex flex-wrap items-center mt-2 mb-2">
                {isLoading ? <i className="w-full fas fa-spin fa-spinner py-3"></i> : (
                  <>
                    <h4 className="text-blueGray-500 ml-2">
                      <b>Keluar</b>
                    </h4>
                    <div
                      className="flex flex-grow items-center bg-opacity-0 shadow-none block list-none ml-auto"
                    >
                      <ul className="flex flex-col lg:flex-row list-none ml-auto">
                        <li className="flex items-center">
                          <span
                            className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          >
                            <img
                              alt="logout"
                              src={require("assets/img/icon/logout.png").default}
                              style={{width: 25}}
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </>
                  )
                }
              </div>
            </button>
          </div>

        </div>
      </div>
    </>
  );
}
