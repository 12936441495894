import React from "react";
import Snackbar from '@material-ui/core/Snackbar';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
// components

import axios from 'axios-jsonp-pro';
import * as Constant from "../../../helpers/Constant";
import { Link } from "react-router-dom";

const GoldRadio = withStyles({
  root: {
    color: '#e0bf4b',
    '&$checked': {
      color: '#e0bf4b',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function CardStepperLogamMulia({setAction}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [showToast, setShowToast] = React.useState(false);
  const [messageToast, setMessageToast] = React.useState('Berat logam mulia tidak boleh kosong!');
  const [formValue, setformValue] = React.useState({
    weight: '',
    packaging: ''
  });
  const [estimation, setEstimation] = React.useState('0');
  const [disable, setDisable] = React.useState(true);
  const [isOverload, setIsOverload] = React.useState(false);

  const handleChange = (event) => {
    setDisable(true);
    setIsOverload(false);
    if (event.target.value.length > 0) setDisable(false);
    if (event.target.value > 1000) {
      setShowToast(true);
      setMessageToast('Berat logam mulia tidak boleh melebihi 1000g!');
      setIsOverload(true);
      setDisable(true);
    }

    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    })
  }

  const handleHideToast = () => {
    setShowToast(false);
  };

  const handleCalculation = async () => {
    // e.preventDefault();
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append('weight', formValue.weight);
    bodyFormData.append('is_certieye', formValue.packaging);
    bodyFormData.append('type', 'lm')
    
    return await axios.post(Constant.APIPOS_LAKUEMAS + '/butik/calculation', bodyFormData)
    .then(res => {
      // console.log(res.data.result);
      setIsLoading(false);
      setEstimation(res.data.result * 0.9);
      setStep(oldKey => oldKey +1);
    })
    .catch(error => {
      console.log(error.message);
      setIsLoading(false);
      // setShowAlert(true);
      // setMessageToast(error.message);
    });
  };

  React.useEffect(() => {
    // console.log(step);
  }, [step]);

  const switchMessageToast = () => {
    switch(step) {
      case 0:
        return 'Berat logam mulia tidak boleh kosong!';
      case 1:
        return 'Jenis kemasan logam mulia tidak boleh kosong!';
      default:
        return '0';
    }
  }

  const renderSwitch = () => {
    switch(step) {
      case 0:
        return (<>
          <h5 className="text-xl font-semibold pb-4 mt-16">
            Masukkan berat logam mulia yang ingin Anda gadaikan
          </h5>
          <div className="mx-auto flex flex-wrap">
            <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12">
              <input
                onChange={(e) => handleChange(e)}
                type="number"
                min="1"
                pattern="[0-9]+"
                name="weight"
                className="border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Masukkan berat logam mulia (gram)"
              />
            </div>
          </div>
        </>);
      case 1:
        return (<>
          <h5 className="text-xl font-semibold pb-4 mt-16">
            Pilih jenis kemasan logam mulia Anda
          </h5>
          <RadioGroup aria-label="packaging" name="packaging" value={formValue.packaging} onChange={(e) => handleChange(e)}>
            <FormControlLabel value="1" control={<GoldRadio />} label="CertiEye" />
            <FormControlLabel value="0" control={<GoldRadio />} label="Non CertiEye" />
          </RadioGroup>
        </>);
      case 2:
          return (<>
            <h5 className="text-xl font-semibold pb-4 mt-16">
              Periksa kembali input hitungan Anda
            </h5>
            <p>Berat Logam Mulia : {formValue.weight} gram <button onClick={() => setStep(0)} className="text-gold">ubah</button></p>
            <p>Jenis Kemasan : {formValue.packaging === '1' ? 'CertiEye' : 'Non CertiEye'} <button onClick={() => setStep(1)} className="text-gold">ubah</button></p>
          </>);
      case 3:
          return (<>
            <h5 className="text-xl pb-4 mt-16">
              Dengan berat logam mulia <span className="text-gold">{formValue.weight} gram</span> dan jenis kemasan <span className="text-gold">{formValue.packaging === '1' ? 'CertiEye' : 'Non CertiEye'}</span>, estimasi pinjaman yang akan Anda dapatkan sebesar <span className="text-gold">Rp{estimation.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span><span style={{color: 'red'}}>*</span>
            </h5>
            <p style={{color: 'red'}}>*nilai berdasarkan harga hari ini</p>
            <br></br>
            <br></br>
            <br></br>
            <i>Hasil perhitungan di atas merupakan estimasi. Yuk unggah foto barang dan invoice agar kami dapat memberikan nilai yang lebih akurat.</i>
            <br></br>
            <br></br>
          </>);
      default:
        return (<>
          <h5 className="text-xl font-semibold pb-4 mt-16"> 
            Masukkan berat perhiasan emas yang ingin Anda gadaikan
          </h5>
          <input
            type="text"
            className="border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder="Masukkan berat perhiasan emas (gram)"
          />
        </>);
    }
  };

  return (
    <>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={showToast}
          autoHideDuration={2000}
          onClose={handleHideToast}
          message={isOverload ? messageToast : switchMessageToast()}
        />
      </div>

      {
        step < 3 ? (
          <>
            <div className="container mx-auto items-center flex flex-wrap">
              <div className="bg-gold rounded" style={{height: 8, width: 70, marginRight: 20}}></div>
              <div className={ step < 1 ? "bg-black-100 rounded" : "bg-gold rounded"} style={{height: 8, width: 70, marginRight: 20}}></div>
              <div className={ step < 2 ? "bg-black-100 rounded" : "bg-gold rounded"} style={{height: 8, width: 70}}></div>
            </div>
          </>
        ) : <></>
      }

      {renderSwitch()}

      {
        step < 3 ? (
          <>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <button
              onClick={() => step === 0 ? setAction('radio') : setStep(oldKey => oldKey -1)}
              className="bg-gray text-gold active:bg-gold text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              Kembali
            </button>
            <button
              onClick={disable ? () => setShowToast(true) : step === 2 ? () => handleCalculation() : () => {setStep(oldKey => oldKey +1); step < 1 ? setDisable(true) : setDisable(false)}}
              className="bg-gold text-white active:bg-gold text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              {step === 2 ? isLoading ? <i className="fas fa-spin fa-spinner"></i> : 'Selanjutnya' : 'Selanjutnya'}
            </button>
          </>
        ) : <>
          {/* <Link
            to="/member"
            className="bg-gold text-white active:bg-gold text-sm font-bold uppercase px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
              Unggah Foto
          </Link> */}
          <button
              onClick={() => setAction('radio')}
              className="bg-gray text-gold active:bg-gold text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
          >
            Simulasi Baru
          </button>
        </>
      }
    </>
  );
}
