import React, {useCallback} from "react";
import Snackbar from '@material-ui/core/Snackbar';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

import CardStepperEmas from "./CardStepperEmas";
import CardStepperBerlian from "./CardStepperBerlian";
import CardStepperLogamMulia from "./CardStepperLogamMulia";

// components

const GoldRadio = withStyles({
  root: {
    color: '#e0bf4b',
    '&$checked': {
      color: '#e0bf4b',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function CardGadaiEmas() {
  const [action, setAction] = React.useState('radio');
  const [type, setType] = React.useState(0);
  const [showToast, setShowToast] = React.useState(false);
  const [disable, setDisable] = React.useState(true);

  const handleChange = (event, newValue) => {
    setDisable(false);
    setType(newValue);
  };

  const handleChangeAction = (e) => {
    setAction(e);
  }

  const handleSetAction = useCallback((e) => {
    setAction(e);
  }, []);

  const handleHideToast = () => {
    setShowToast(false);
  };

  const renderSwitch = () => {
    switch(type) {
      case '0':
        return <CardStepperEmas setAction={handleSetAction} />;
      case '1':
        return <CardStepperBerlian setAction={handleSetAction} />;
      case '2':
          return <CardStepperLogamMulia setAction={handleSetAction} />;
      default:
        return <CardStepperEmas setAction={handleSetAction} />;
    }
  };

  return (
    <>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={showToast}
          autoHideDuration={2000}
          onClose={handleHideToast}
          message='Pilih jenis barang yang ingin Anda gadaikan!'
        />
      </div>

      {
        action === 'radio' ? (
          <>
            <h5 className="text-xl font-semibold pb-4">
              Pilih jenis barang yang ingin Anda gadaikan
            </h5>
            
            <RadioGroup aria-label="type" name="type" value={type} onChange={handleChange}>
              <FormControlLabel value="0" control={<GoldRadio />} label="Perhiasan Emas" />
              <FormControlLabel value="1" control={<GoldRadio />} label="Perhiasan Berlian" />
              <FormControlLabel value="2" control={<GoldRadio />} label="Logam Mulia Batangan" />
            </RadioGroup>
            
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <button
              onClick={() => disable ? setShowToast(true) : handleChangeAction('stepper')}
              className="bg-gold text-white active:bg-gold text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              // disabled={disable}
            >
              Selanjutnya
            </button>
          </>
        ) : renderSwitch()
      }
      
    </>
  );
}
