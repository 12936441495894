import React from "react";
// import axios from 'axios-jsonp-pro';
// import * as Constant from "../../helpers/Constant";
// import Skeleton from 'react-loading-skeleton';

// components

export default function SectionMechanism() {
  // const [isLoading, setIsLoading] = React.useState(false);
  // const [abouts, setAbouts] = React.useState({});

  // React.useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   return await axios.get(Constant.BASEURL + 'about/1')
  //   .then(res => {
  //     setIsLoading(false);
  //     setAbouts(res.data.data.about);
  //   })
  //   .catch(error => {
  //     console.log(error.message);
  //   });
  // }

  return (
    <>
      <section className="pb-20 relative block bg-blueGray-800 py-20">
        
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage:
            "url(" + require("assets/img/icon/bg_luxury.png").default + ")",
          }}
        >
          <span
            id="blackOverlay"
            className="w-full h-full absolute"
          ></span>
        </div>

        <div className="container relative mx-auto px-4">
          <div className="flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-4xl font-semibold text-white">
                Mekanisme Persyaratan Gadai
              </h2>
            </div>
          </div>

          <div className="flex flex-wrap mt-12 justify-center">
            
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex min-w-0 break-words bg-gold w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex">
                  <div className="md:w-112/12 px-4">
                    <h6 className="text-xl font-semibold text-white">PERSYARATAN PENCAIRAN</h6>
                    
                    <ul className="list-none">
                      <li className="py-4">
                        <div className="flex items-center">
                          <div style={{width: '10%'}}>
                            <img
                              alt="identification-card"
                              src={require("assets/img/icon/identification_card.png").default}
                              // className="rounded-full mx-auto max-w-120-px"
                            />
                          </div>
                          <div style={{width: '90%'}}>
                            <h4 className="text-white pl-4">
                              1. Fotocopy KTP atau identitas lainnya
                            </h4>
                          </div>
                        </div>
                      </li>

                      <li className="py-4">
                        <div className="flex items-center">
                          <div style={{width: '10%'}}>
                            <img
                              alt="give-diamond"
                              src={require("assets/img/icon/give_diamond.png").default}
                              // className="rounded-full mx-auto max-w-120-px"
                            />
                          </div>
                          <div style={{width: '90%'}}>
                            <h4 className="text-white pl-4">
                              2. Menyerahkan barang jaminan
                            </h4>
                          </div>
                        </div>
                      </li>

                      <li className="py-4">
                        <div className="flex items-center">
                          <div style={{width: '10%'}}>
                            <img
                              alt="form-document"
                              src={require("assets/img/icon/form_document.png").default}
                              // className="rounded-full mx-auto max-w-120-px"
                            />
                          </div>
                          <div style={{width: '90%'}}>
                            <h4 className="text-white pl-4">
                              3. Mengisi formulir permohonan gadai
                            </h4>
                          </div>
                        </div>
                      </li>

                      <li className="py-4">
                        <div className="flex items-center">
                          <div style={{width: '10%'}}>
                            <img
                              alt="sign-contract"
                              src={require("assets/img/icon/sign_contract.png").default}
                              // className="rounded-full mx-auto max-w-120-px"
                            />
                          </div>
                          <div style={{width: '90%'}}>
                            <h4 className="text-white pl-4">
                              4. Nasabah menandatangani Surat Bukti Gadai (SBG)
                            </h4>
                          </div>
                        </div>
                      </li>

                    </ul>
                  
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4">
              <div className="flex min-w-0 break-words bg-gold w-full mb-8 shadow-lg rounded-lg">
                <div className="px-4 py-5 flex">
                  <div className="md:w-112/12 px-4">
                    <h6 className="text-xl font-semibold text-white">PERSYARATAN GADAI ULANG</h6>
                    <ul className="list-none">
                      <li className="py-4">
                        <div className="flex items-center">
                          <div style={{width: '10%'}}>
                            <img
                              alt="identification-card"
                              src={require("assets/img/icon/identification_card.png").default}
                              // className="rounded-full mx-auto max-w-120-px"
                            />
                          </div>
                          <div style={{width: '90%'}}>
                            <h4 className="text-white pl-4">
                              1. Fotocopy KTP atau identitas lainnya
                            </h4>
                          </div>
                        </div>
                      </li>

                      <li className="py-4">
                        <div className="flex items-center">
                          <div style={{width: '10%'}}>
                            <img
                              alt="give-diamond"
                              src={require("assets/img/icon/give_diamond.png").default}
                              // className="rounded-full mx-auto max-w-120-px"
                            />
                          </div>
                          <div style={{width: '90%'}}>
                            <h4 className="text-white pl-4">
                              2. Menyiapkan dana untuk gadai ulang
                            </h4>
                          </div>
                        </div>
                      </li>

                      <li className="py-4">
                        <div className="flex items-center">
                          <div style={{width: '10%'}}>
                            <img
                              alt="form-document"
                              src={require("assets/img/icon/form_document.png").default}
                              // className="rounded-full mx-auto max-w-120-px"
                            />
                          </div>
                          <div style={{width: '90%'}}>
                            <h4 className="text-white pl-4">
                              3. Nasabah membawa Surat Bukti Gadai (SBG) yang lama untuk diganti dengan yang baru
                            </h4>
                          </div>
                        </div>
                      </li>

                    </ul>
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </section>
    </>
  );
}
