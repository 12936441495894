import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import About from "views/admin/About.js";
import Content from "views/admin/Content.js";
import Product from "views/admin/Product.js";
import News from "views/admin/News.js";
import Partner from "views/admin/Partner.js";
import Faq from "views/admin/Faq.js";
import Diamond from "views/admin/Diamond.js";
import User from "views/admin/User.js";
import Profile from "views/admin/Profile.js";

import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/about" exact component={About} />
            <Route path="/admin/content" exact component={Content} />
            <Route path="/admin/product" exact component={Product} />
            <Route path="/admin/news" exact component={News} />
            <Route path="/admin/partner" exact component={Partner} />
            <Route path="/admin/faq" exact component={Faq} />
            <Route path="/admin/diamond" exact component={Diamond} />
            <Route path="/admin/user" exact component={User} />
            <Route path="/admin/profile" exact component={Profile} />

            <Route path="/admin/maps" exact component={Maps} />
            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/tables" exact component={Tables} />

            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
