import React from "react";
import axios from 'axios-jsonp-pro';
import { useHistory } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import * as Constant from '../../helpers/Constant.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// components

export default function CardAbout() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [showToast, setShowToast] = React.useState(false);
  const [refreshKey, setRefreshKey] = React.useState(0);
  const [formValue, setformValue] = React.useState({
    id: null,
    name: '',
    open_hours: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    postal_code: '',
    map: '',
    short_description: '',
    description: '',
    image: '',
  });
  const [image, setImage] = React.useState(null);
  const [imagePreview, setImagePreview] = React.useState('');

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    })
  }

  const handleHideToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const onFileChange = (event) => {
    setImage(event.target.files[0]);
    setImagePreview(event.target.files.length > 0 ? URL.createObjectURL(event.target.files[0]) : '');
  }; 

  const history = useHistory();
  React.useEffect(() => {
    const fetchData = async () => {
      return await axios.get(Constant.URL + '/about/show/1', {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('token'),
        }
      })
      .then(res => {
        setformValue(res.data.data.about);
        setImagePreview(Constant.BASEURL + res.data.data.about.image);
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 401') {
          localStorage.clear();
          history.push("/admin");
        }
      });
    }
    fetchData();
  }, [refreshKey, history]);

  const handleUpdate = async () => {
    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append('name', formValue.name);
    bodyFormData.append('open_hours', formValue.open_hours);
    bodyFormData.append('email', formValue.email);
    bodyFormData.append('phone', formValue.phone);
    bodyFormData.append('address', formValue.address);
    bodyFormData.append('city', formValue.city);
    bodyFormData.append('country', formValue.country);
    bodyFormData.append('postal_code', formValue.postal_code);
    bodyFormData.append('map', formValue.map);
    bodyFormData.append('description', formValue.description);
    bodyFormData.append('image', image);
    bodyFormData.append('_method', 'PUT');

    return await axios.post(Constant.URL + '/about/update/1', bodyFormData, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('token'),
      }
    })
    .then(res => {
      console.log(res.data.data);
      setShowToast(true);
      setIsLoading(false);
      // fetchData();
      setRefreshKey(oldKey => oldKey +1);
    })
    .catch(error => {
      setIsLoading(false);
      
      if (error.message === 'Request failed with status code 401') {
        localStorage.clear();
        history.push("/admin");
      }

      setShowAlert(true);
      setMessageError(error.message);
    });
  }

  return (
    <>
      {showAlert ? (
        <Dialog
          open={showAlert}
          onClose={() => setShowAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
          <DialogContent style={{minWidth: 300}}>
            <DialogContentText id="alert-dialog-description">
              {messageError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => setShowAlert(false)}
              className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
      ) : null}

      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={showToast}
          autoHideDuration={1500}
          onClose={handleHideToast}
          message="Success Update Data"
        />
      </div>

      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">About</h6>
            <button
              // onClick={() => setRefreshKey(oldKey => oldKey +1)}
              onClick={() => handleUpdate()}
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              {!isLoading ? 'Update' : <i className="fas fa-spin fa-spinner"></i>}
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Company Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Company Name
                  </label>
                  <input type="hidden" name="id" value={formValue.id}/>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="name"
                    value={formValue.name}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Open Hours
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="open_hours"
                    value={formValue.open_hours}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email Address
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="email"
                    name="email"
                    value={formValue.email}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Phone Number
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="phone"
                    value={formValue.phone}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Contact Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Address
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="address"
                    value={formValue.address}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    City
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="city"
                    value={formValue.city}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Country
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="country"
                    value={formValue.country}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Postal Code
                  </label>
                  <input
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="postal_code"
                    value={formValue.postal_code}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Map
                  </label>
                  <textarea
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="map"
                    value={formValue.map}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              About Me
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    About me
                  </label>
                  <textarea
                    onChange={(e) => handleChange(e)}
                    type="text"
                    name="description"
                    value={formValue.description}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Image
            </h6>
            <div className="text-center">
              <img alt="about" src={imagePreview} height="100" style={{margin: 'auto', height: '150px'}}/>
              <br></br>
              <input type="file" name="image" onChange={(e) => onFileChange(e)} className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" />
            </div>

          </form>
        </div>
      </div>
    </>
  );
}
