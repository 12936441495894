import React from "react";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios-jsonp-pro';
import * as Constant from '../../helpers/Constant.js';

export default function Detail() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [pawn, setPawn] = React.useState({});
  const [formType, setFormType] = React.useState('');
  const [address, setAddress] = React.useState({});

  const history = useHistory();
  
  const url = window.location.pathname;
  const id = url.split("/").pop();

  React.useEffect(() => {
    const getSinglePawn = async () => {
      setIsLoading(true);
      return await axios.get(Constant.URL + '/pawn-detail/' + id, {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('tokenMember'),
        }
      })
      .then(res => {
        // console.log(res.data.data.pawn);
        setPawn(res.data.data.pawn);
        setFormType(res.data.data.form_type);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
    }

    const getMemberAddress = async () => {
      // setIsLoading(true);
      return await axios.get(Constant.URL + '/member-address', {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('tokenMember'),
        }
      })
      .then(res => {
        // console.log(res.data.data.address);
        // setIsLoading(false);
        setAddress(res.data.data.address);
      })
      .catch(error => {
        setIsLoading(false);
      });
    }

    getSinglePawn();
    getMemberAddress();
  }, [history, id]);

  const formatedMonthName = (date) => {
    const  months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

    return months[date.getMonth()];
  }

  return (
    <>
      <div className="flex pt-16 h-full h-auto">
              
      {
        isLoading ? (
          <>
            <tr>
              <td colSpan="6" className="text-center">
                <br></br>
                <i className="fas fa-spin fa-spinner"></i>
                <br></br>
                <br></br>
              </td>
            </tr>
          </>
        ) : (
        <>
        <div className="w-full lg:w-12/12 px-4">

          <div className="relative flex flex-col min-w-0 break-words bg-white shadow-lg w-full rounded-lg mt-3 mb-3">
            <div className="flex-auto">
              <div className="px-6 py-4">
                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    Status Gadai
                  </h4>
                  <h4 className="text-blueGray-500">
                    <b>{pawn.status}</b>
                  </h4>
                </div>

                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    Jenis Barang
                  </h4>
                  <h4 className="text-blueGray-500">
                    <b>{pawn.pawn_item ? pawn.pawn_item[0].pawn_item_type.text : null}</b>
                  </h4>
                </div>

                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    Total Pinjaman
                  </h4>
                  <h4 className="text-blueGray-500">
                    <b>Rp. {pawn.loan ? pawn.loan.toLocaleString(undefined, {maximumFractionDigits:2}) : null}</b>
                  </h4>
                </div>

                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    Tanggal Jatuh Tempo
                  </h4>
                  <h4 className="text-blueGray-500">
                    <b>{new Date(pawn.due_date).getDate()} {formatedMonthName(new Date(pawn.due_date))} {new Date(pawn.due_date).getFullYear()}</b>
                  </h4>
                </div>
              </div>

              <div className="w-full flex flex-row items-center justify-between bg-green px-4 py-4" style={{borderBottomRightRadius: 9, borderBottomLeftRadius: 9}}>
                <div className=" justify-start">
                  <img
                    alt="arrow-right"
                    src={require("assets/img/icon/info.png").default}
                    className="rounded-full mx-auto"
                    style={{width: 30}}
                  />
                </div>
                <h4 className="text-sm ml-2">
                  Barang Anda berhasil digadaikan. Silakan hubungi kami jika terdapat kendala.
                </h4>
              </div>

            </div>
          </div>

          <div className="w-full mb-3 bg-white px-4 py-1 shadow-lg rounded-lg mt-3">
            <Link className="mx-auto flex flex-wrap items-center justify-between mt-2 mb-2">
              <h4 className="text-blueGray-500 ml-2">
                <b>Surat Bukti Gadai</b>
              </h4>
              <div
                className="flex flex-grow items-center bg-opacity-0 shadow-none block  list-none ml-auto"
              >
                <ul className="flex flex-row list-none ml-auto">
                  <li>
                    <div className="w-full mb-1 bg-blue text-sm px-4 py-2 rounded-lg mt-1">
                      Sudah Tanda Tangan
                    </div>
                  </li>
                  <li className="flex items-center">
                    <Link
                      className="hover:text-blueGray-500 text-blueGray-700 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      target="_blank"
                    >
                      <img
                        alt="chevron_right"
                        src={require("assets/img/icon/chevron_right.png").default}
                        style={{width: 30}}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </Link>
            <hr></hr>

            <Link className="mx-auto flex flex-wrap items-center justify-between mt-2 mb-2">
              <h4 className="text-blueGray-500 ml-2">
                <b>Surat Segel</b>
              </h4>
              <div
                className="flex flex-grow items-center bg-opacity-0 shadow-none block list-none ml-auto"
              >
                <ul className="flex flex-col lg:flex-row list-none ml-auto">

                  <li className="flex items-center">
                    <Link
                      className="hover:text-blueGray-500 text-blueGray-700 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      target="_blank"
                    >
                      <img
                        alt="chevron_right"
                        src={require("assets/img/icon/chevron_right.png").default}
                        style={{width: 30}}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </Link>
          </div>

          <div className="relative flex flex-col min-w-0 break-words bg-white shadow-lg w-full rounded-lg mt-3 mb-3">
            <div className="flex-auto">
              <div className="px-6 py-4">
                
                <Link className="mx-auto flex flex-wrap items-center justify-between">
                  <h4 className="text-blueGray-500">
                    <b>Detail Gadai</b>
                  </h4>
                  <div
                    className="flex flex-grow items-center bg-opacity-0 shadow-none block list-none ml-auto"
                  >
                    <ul className="flex flex-col lg:flex-row list-none ml-auto">

                      <li className="flex items-center">
                        <Link
                          className="hover:text-blueGray-500 text-blueGray-700 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          target="_blank"
                        >
                          <img
                            alt="expand_less"
                            src={require("assets/img/icon/expand_less.png").default}
                            style={{width: 30}}
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Link>

                <hr></hr>
              
                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    <b>Tanggal Transaksi</b>
                  </h4>
                  <h4 className="text-blueGray-500">
                  {new Date(pawn.date_transaction).getDate()} {formatedMonthName(new Date(pawn.date_transaction))} {new Date(pawn.date_transaction).getFullYear()}
                  </h4>
                </div>

                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    <b>Detail Barang Jaminan</b>
                  </h4>
                  <h4 className="text-blueGray-500">
                    Perhiasan Emas
                  </h4>
                </div>

                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    <b>Nilai Taksiran</b>
                  </h4>
                  <h4 className="text-blueGray-500">
                    Rp. {pawn.total_appraisal_price ? pawn.total_appraisal_price.toLocaleString(undefined, {maximumFractionDigits:2}) : null}
                  </h4>
                </div>

                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    <b>Nilai Pinjaman</b>
                  </h4>
                  <h4 className="text-blueGray-500">
                    Rp. {pawn.loan ? pawn.loan.toLocaleString(undefined, {maximumFractionDigits:2}) : null}
                  </h4>
                </div>

                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    <b>Tenor</b>
                  </h4>
                  <h4 className="text-blueGray-500">
                    {/* 12 Bulan */}
                    {pawn.pawn_tenor ? pawn.pawn_tenor.day : null} Hari
                  </h4>
                </div>

                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    <b>Biaya Administrasi</b>
                  </h4>
                  <h4 className="text-blueGray-500">
                    Rp. {pawn.admin_fee ? pawn.admin_fee.toLocaleString(undefined, {maximumFractionDigits:2}) : null}
                  </h4>
                </div>

                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    <b>Tanggal Jatuh Tempo</b>
                  </h4>
                  <h4 className="text-blueGray-500">
                    {new Date(pawn.due_date).getDate()} {formatedMonthName(new Date(pawn.due_date))} {new Date(pawn.due_date).getFullYear()}
                  </h4>
                </div>

                <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    <b>Tanggal Penjualan Terakhir</b>
                  </h4>
                  <h4 className="text-blueGray-500">
                    {new Date(pawn.last_sale_date).getDate()} {formatedMonthName(new Date(pawn.last_sale_date))} {new Date(pawn.last_sale_date).getFullYear()}
                  </h4>
                </div>
              </div>

            </div>
          </div>



          <div className="relative flex flex-col min-w-0 break-words bg-white shadow-lg w-full rounded-lg mt-3 mb-3">
            <div className="flex-auto">
              <div className="px-6 py-4">
                
                <Link className="mx-auto flex flex-wrap items-center justify-between">
                  <h4 className="text-blueGray-500">
                    <b>Alamat Saya</b>
                  </h4>
                  <div
                    className="flex flex-grow items-center bg-opacity-0 shadow-none block list-none ml-auto"
                  >
                    <ul className="flex flex-col lg:flex-row list-none ml-auto">

                      <li className="flex items-center">
                        <Link
                          className="hover:text-blueGray-500 text-blueGray-700 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          target="_blank"
                        >
                          <img
                            alt="expand_less"
                            src={require("assets/img/icon/expand_less.png").default}
                            style={{width: 30}}
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Link>

                <hr></hr>

                <div className="mx-auto flex=col flex-wrap items-center justify-between mt-3 mb-3">

                  <h4 className="text-blueGray-500">
                    <b>Alamat Lengkap</b>
                  </h4>
                  <h4 className="text-blueGray-500">
                    {address.address}
                  </h4>
                  
                  {/* <h4 className="text-blueGray-500">
                    <b>Lokasi</b>
                  </h4>
                  <h4 className="text-blueGray-500">
                    Jalan Sapta Taruna I no 13 RT 01 RW 01 Kebayoran Lama Jakarta Selatan 12310
                  </h4> */}
                </div>
              </div>

            </div>
          </div>


          <div className="relative flex flex-col min-w-0 break-words bg-white shadow-lg w-full rounded-lg mt-3 mb-3">
            <div className="flex-auto">
              <div className="px-6 py-4">
                
                <Link className="mx-auto flex flex-wrap items-center justify-between">
                  <h4 className="text-blueGray-500">
                    <b>Detail Barang</b>
                  </h4>
                  <div
                    className="flex flex-grow items-center bg-opacity-0 shadow-none block list-none ml-auto"
                  >
                    <ul className="flex flex-col lg:flex-row list-none ml-auto">

                      <li className="flex items-center">
                        <Link
                          className="hover:text-blueGray-500 text-blueGray-700 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                          target="_blank"
                        >
                          <img
                            alt="expand_less"
                            src={require("assets/img/icon/expand_less.png").default}
                            style={{width: 30}}
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Link>

                <hr></hr>

                <div className="mx-auto flex=col flex-wrap items-center justify-between mt-3 mb-3">
                  <h4 className="text-blueGray-500">
                    <b>Berat Perhiasan Emas</b>
                  </h4>
                  <h4 className="text-blueGray-500 mb-4">
                    {pawn.pawn_item ? pawn.pawn_item[0].weight : null} Gram
                  </h4>

                  <h4 className="text-blueGray-500">
                    <b>Kadar Perhiasan Emas</b>
                  </h4>
                  <h4 className="text-blueGray-500 mb-4">
                    {pawn.pawn_item ? pawn.pawn_item[0].carat : null} Karat
                  </h4>

                  <h4 className="text-blueGray-500">
                    <b>Foto Barang</b>
                  </h4>
                  <div className="flex flex-wrap items-center">
                    <div className="w-full md:w-6/12 px-2 mr-auto ml-auto">
                      <div className="w-full md:w-12/12 text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg">
                          <div className="py-5 flex-auto">
                            <div className="text-center inline-flex items-center justify-center">
                              <img
                                alt="video-conferencing"
                                src={pawn.pawn_item ? "https://sgkbucketdev.oss-ap-southeast-5.aliyuncs.com/pawn/" + pawn.pawn_item[0].photo : null}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="w-full md:w-6/12 px-2 mr-auto ml-auto">
                      <div className="w-full md:w-12/12 text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg">
                          <div className="py-5 flex-auto">
                            <div className="text-center inline-flex items-center justify-center">
                              <img
                                alt="remote-working-challages"
                                src={require("assets/img/icon/remote_working_challenges.png").default}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <h4 className="text-blueGray-500">
                    <b>Foto Invoice Pembelian</b>
                  </h4>
                  <div className="flex flex-wrap items-justify">

                    <div className="w-full md:w-6/12 px-2 mr-auto ml-auto">
                      <div className="w-full md:w-12/12 text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg">
                          <div className="py-5 flex-auto">
                            <div className="text-center inline-flex items-center justify-center">
                              <img
                                alt="application_form_photo"
                                src={pawn ? "https://sgkbucketdev.oss-ap-southeast-5.aliyuncs.com/pawn/document/" + pawn.application_form_photo : null}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    {
                      formType === 'C' ? (
                        <>
                          <div className="w-full md:w-6/12 px-2 mr-auto ml-auto">
                            <div className="w-full md:w-12/12 text-center">
                              <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg">
                                <div className="py-5 flex-auto">
                                  <div className="text-center inline-flex items-center justify-center">
                                    <img
                                      alt="pawn_form_photo"
                                      src={pawn ? "https://sgkbucketdev.oss-ap-southeast-5.aliyuncs.com/pawn/document/" + pawn.pawn_form_photo : null}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : ''
                    }
                    
                    {
                      formType === 'A' ? (
                        <>
                          <div className="w-full md:w-6/12 px-2 mr-auto ml-auto">
                            <div className="w-full md:w-12/12 text-center">
                              <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg">
                                <div className="py-5 flex-auto">
                                  <div className="text-center inline-flex items-center justify-center">
                                    <img
                                      alt="sbg_form_photo"
                                      src={pawn ? "https://sgkbucketdev.oss-ap-southeast-5.aliyuncs.com/pawn/document/" + pawn.sbg_form_photo : null}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="w-full md:w-6/12 px-2 mr-auto ml-auto">
                            <div className="w-full md:w-12/12 text-center">
                              <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg">
                                <div className="py-5 flex-auto">
                                  <div className="text-center inline-flex items-center justify-center">
                                    <img
                                      alt="surat_kuasa_photo"
                                      src={pawn ? "https://sgkbucketdev.oss-ap-southeast-5.aliyuncs.com/pawn/document/" + pawn.surat_kuasa_photo : null}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : ''
                    }

                    {/* <div className="w-full md:w-6/12 px-2 mr-auto ml-auto">
                      <div className="w-full md:w-12/12 text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg">
                          <div className="py-5 flex-auto">
                            <div className="text-center inline-flex items-center justify-center">
                              <img
                                alt="seal_form_photo"
                                src={pawn ? "https://sgkbucketdev.oss-ap-southeast-5.aliyuncs.com/pawn/document/" + pawn.seal_form_photo : null}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-6/12 px-2 mr-auto ml-auto">
                      <div className="w-full md:w-12/12 text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg">
                          <div className="py-5 flex-auto">
                            <div className="text-center inline-flex items-center justify-center">
                              <img
                                alt="sealed_product_photo"
                                src={pawn ? "https://sgkbucketdev.oss-ap-southeast-5.aliyuncs.com/pawn/document/" + pawn.sealed_product_photo : null}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                  </div>

                  <h4 className="text-blueGray-500">
                    <b>VIdeo Barang</b>
                  </h4>
                  <div className="flex flex-wrap items-justify">
                    <div className="w-full w-12/12 px-2 mr-auto ml-auto">
                      <div className="w-full w-12/12 text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg">
                          <div className="py-5 flex-auto">
                            <div className="text-center inline-flex items-center justify-center">
                              <img
                                alt="video-conferencing"
                                src={require("assets/img/icon/video_conferencing.png").default}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>

            </div>
          </div>

        </div> </>)

        }
      </div>
    </>
  );
}
