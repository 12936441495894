import React from "react";
import axios from 'axios-jsonp-pro';
import * as Constant from "../../helpers/Constant";
// import Skeleton from 'react-loading-skeleton';

import { useParams } from "react-router-dom";

// components
export default function SectionPay() {
  const { id } = useParams();
  const [customer, setCustomer] = React.useState({});
  const [pawn, setPawn] = React.useState({});
  const [tenor, setTenor] = React.useState({});
  const [midtrans, setMidtrans] = React.useState([]);
  const [expiredAt, setExpiredAt] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const [dataAvailable, setDataAvailable] = React.useState(null);
  const [penalty, setPenalty] = React.useState({});
  const [interest, setInterest] = React.useState({});
  const [paymentStatus, setPaymentStatus] = React.useState('');

  const formatedMonthName = (date) => {
    const  months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

    return months[date.getMonth()];
  }
  
  React.useEffect(() => {
    const fetchData = async () => {
      return await axios.get(Constant.BASEURL + 'pay/' + id)
      .then(res => {
        // console.log('Payment Status ', res.data.data.data.payment_status);
        setIsLoading(false);
        
        setPaymentStatus(res.data.data.data.payment_status);

        if (res.data.data.message === 'Transaction not found !') {
          setDataAvailable(false);
        } else {
          setDataAvailable(true);
          setCustomer(res.data.data.data.customer);
          setPawn(res.data.data.data.pawn);
          setTenor(res.data.data.data.getTenor);
          setMidtrans(res.data.data.data.midtrans.response);
          setExpiredAt(res.data.data.data.expired_at);
          setPenalty(res.data.data.data.penalty);
          setInterest(res.data.data.data.interest);
        }
      })
      .catch(error => {
        setIsLoading(false);
        // console.log(error.message);
      });
    }
    fetchData();
  }, [id]);

  const capitalize = (str) => { 
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
        <section className="pb-16 bg-blueGray-200 relative pt-32">

          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">

              {
                !dataAvailable ? (
                  <div className="w-full w12 text-center bg-white rounded-lg pt-8 pb-8">
                    {
                      isLoading ? (
                        <>Tunggu sebentar</>
                      ) : (
                        <>
                          <h3 className="font-semibold text-3xl text-center">
                            Maaf
                          </h3>
                          <br></br>
                          Data Tidak Ditemukan!
                        </>
                      )
                    }
                  </div>
                ) : paymentStatus === 'paid' ? (
                  <div className="w-full w12 text-center bg-white rounded-lg pt-8 pb-8">
                      <h3 className="font-semibold text-3xl text-center">
                        Kode Transaksi {pawn.application_number}
                      </h3>
                      <br></br>
                      Sudah terbayar
                  </div>
                ) : 
                (
                  <>
                    <div className="w-full lg:w-6/12 sm:w-12/12 px-4">
                      <div className="w-full text-center bg-white rounded-lg pt-8 pb-8 mb-4" style={{height: 200}}>
                        <b>Informasi Pelanggan</b>
                        <br></br>
                        <br></br>
                        <b>{customer.name}</b>
                        <br></br>
                        {customer.handphone}
                        <br></br>
                        {customer.email}
                        <br></br>
                      </div>

                      <div className="w-full text-center bg-white rounded-lg pt-8 pb-8" style={{height: penalty === 0 ? 400 : 420}}>
                        <b>Informasi Gadai</b>
                        <br></br>
                        <br></br>
                        <div className="flex flex-wrap px-6 pt-4 pb-4">
                          <div className="w-6/12 text-left">
                            <b>Kode Transaksi</b>
                          </div>
                          <div className="w-6/12 text-right">
                            {
                              pawn.application_number
                            }
                          </div>
                        </div>
                        <div className="flex flex-wrap px-6 pt-4 pb-4">
                          <div className="w-6/12 text-left">
                            <b>Pemakaian</b>
                          </div>
                          <div className="w-6/12 text-right">
                            {
                              parseInt(Math.abs(new Date() - new Date(pawn.date_transaction)) / (1000 * 60 * 60 * 24))
                            } Hari
                          </div>
                        </div>
                        <div className="flex flex-wrap px-6 pt-4 pb-4">
                          <div className="w-6/12 text-left">
                            <b>Jatuh Tempo</b>
                          </div>
                          <div className="w-6/12 text-right">
                            {new Date(pawn.due_date).getDate()} {formatedMonthName(new Date(pawn.due_date))} {new Date(pawn.due_date).getFullYear()}
                          </div>
                        </div>
                        <div className="flex flex-wrap px-6 pt-4 pb-4">
                          <div className="w-6/12 text-left">
                            <b>Bunga /{tenor.day} Hari</b>
                          </div>
                          <div className="w-6/12 text-right">
                            {tenor.interest}%
                          </div>
                        </div>
                        
                        {
                          !penalty ? '' : (
                            <>
                              <div className="flex flex-wrap px-6 pt-4 pb-4">
                                <div className="w-6/12 text-left" style={{color: '#f00'}}>
                                  <b>Pinalty (2%)</b>
                                </div>
                                <div className="w-6/12 text-right" style={{color: '#f00'}}>
                                   {penalty.day} Hari (Rp. {parseInt(penalty.nominal).toLocaleString(undefined, {maximumFractionDigits:2})})
                                </div>
                              </div>
                            </>
                          )
                        }

                        <div className="flex flex-wrap px-6 pt-4 pb-4">
                          <div className="w-6/12 text-left">
                            <b>Bunga Nominal ({interest.percentage}%)</b>
                          </div>
                          <div className="w-6/12 text-right">
                            Rp. {parseInt(interest.nominal).toLocaleString(undefined, {maximumFractionDigits:2})}
                          </div>
                        </div>
                        <div className="flex flex-wrap px-6 pt-4 pb-4">
                          <div className="w-6/12 text-left">
                            <b>Pinjaman</b>
                          </div>
                          <div className="w-6/12 text-right">
                            Rp. {Math.ceil(pawn.loan).toLocaleString(undefined, {maximumFractionDigits:2})}
                          </div>
                        </div>
                        <div className="flex flex-wrap px-6 pt-4 pb-4">
                          <div className="w-6/12 text-left">
                            <b>Total</b>
                          </div>
                          <div className="w-6/12 text-right">
                            <b>Rp. {
                              !penalty ? 
                                (Math.ceil(pawn.loan + interest.nominal)).toLocaleString(undefined, {maximumFractionDigits:2})
                              : (Math.ceil(pawn.loan + interest.nominal + penalty.nominal)).toLocaleString(undefined, {maximumFractionDigits:2})
                            }</b>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    </div>

                    <div className="flex flex-wrap w-full lg:w-6/12 sm:w-12/12 px-4 sm:mt-20 md:mt-1rem">
                      <div className="w-full bg-white rounded-lg px-6">
                        <h3 className="font-semibold text-3xl pt-8 text-center">
                          Pelunasan Laku Gadai
                        </h3>
                        <br></br>

                        {
                          midtrans.map((key, index ) => {
                            return (
                              <div key={index} className="flex flex-wrap pt-4 pb-4" style={{borderBottom: '1px solid #cccccc'}}>
                                <div className="w-6/12 pt-8">
                                  <b>{capitalize(key.bank)} Virtual Account</b>
                                </div>
                                <div className="w-6/12 text-right pt-6">
                                  <img src={require("assets/img/bank/" + key.bank + ".png").default} width="80" alt="image_preview" style={{float: 'right'}}></img>
                                  <br></br>
                                  {key.va_no}
                                </div>
                              </div>
                            );
                          })
                        }

                        <div className="flex flex-wrap pt-4 pb-4">
                          <div className="w-6/12 pt-6">
                            <b>Transfer Nominal</b>
                          </div>
                          <div className="w-6/12 text-right pt-6">
                            Rp.  {
                              !penalty ? 
                                (Math.ceil(pawn.loan + interest.nominal)).toLocaleString(undefined, {maximumFractionDigits:2})
                              : (Math.ceil(pawn.loan + interest.nominal + penalty.nominal)).toLocaleString(undefined, {maximumFractionDigits:2})
                            }
                          </div>
                        </div>

                        <div className="flex flex-wrap pt-4 pb-4">
                          <div className="w-6/12 pt-4">
                            <b>Berlaku Hingga</b>
                          </div>
                          <div className="w-6/12 text-right pt-4">
                          {new Date(expiredAt).getDate()} {formatedMonthName(new Date(expiredAt))} {new Date(expiredAt).getFullYear()}
                          </div>
                        </div>

                        <div className="sm:block flex flex-col mt-10 text-center">
                          {/* <Link
                            to="/simulation/0"
                            className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-gold active:bg-gold uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                          >
                            Selesaikan
                          </Link> */}
                        </div>
                        <div className="text-center mt-8"></div>
                      </div>
                    </div>
                  </>
                )
              }
              
            </div>
          </div>
        </section>
    </>
  );
}
