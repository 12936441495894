import React from "react";
// import { Link } from "react-router-dom";

export default function Notification() {
  
  return (
    <>
      <div className="flex pt-16 h-full h-auto">
              
        <div className="w-full lg:w-12/12">

          <div className="w-full bg-white px-4 py-4 mb-3">
            <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
              <label className="inline-flex items-center">
                <span className="ml-2 text-sm font-bold text-blueGray-400">
                  SBG12345678
                </span>
              </label>
              <label className="inline-flex items-center">
                <span className="ml-2 text-sm font-bold text-blueGray-400">
                  20 Agustus 2021
                </span>
              </label>
            </div>
            <hr></hr>

            <div className="w-full md:w-12/12">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full">
                <div className="px-4 py-5 flex-auto">
                  <h6 className="text-xl font-semibold mb-3">Barang Anda Masuk Proses Pra Penilaian</h6>
                  
                  <p>Barang Anda berhasil divalidasi. Kami akan segera mengubungi Anda untuk melakukan proses pra penilaian.</p>

                </div>
              </div>
            </div>
          </div>


          <div className="w-full bg-white px-4 py-4">
            <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
              <label className="inline-flex items-center">
                <span className="ml-2 text-sm font-bold text-blueGray-400">
                  SBG12345678
                </span>
              </label>
              <label className="inline-flex items-center">
                <span className="ml-2 text-sm font-bold text-blueGray-400">
                  20 Agustus 2021
                </span>
              </label>
            </div>
            <hr></hr>

            <div className="w-full md:w-12/12">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full">
                <div className="px-4 py-5 flex-auto">
                  <h6 className="text-xl font-semibold mb-3">Barang Anda Masuk Proses Pra Penilaian</h6>
                  
                  <p>Barang Anda berhasil divalidasi. Kami akan segera mengubungi Anda untuk melakukan proses pra penilaian.</p>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
