import React from "react";
import axios from 'axios-jsonp-pro';
import * as Constant from '../../helpers/Constant.js';
import { useHistory } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';

// components

export default function CardFormFaq({setAction, action, id}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [formValue, setformValue] = React.useState({
    question: '',
    answer: ''
  });

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
  }
  
  const history = useHistory();
  React.useEffect(() => {
    const fetchData = async () => {
      return await axios.get(Constant.URL + '/faq/show/' + id, {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('token')
        }
      })
      .then(res => {
        setformValue(res.data.data.faq);
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 401') {
          localStorage.clear();
          history.push("/admin");
        }
      });
    }
    fetchData();
  }, [id, history]);

  const handleStore = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.append('question', formValue.question);
    bodyFormData.append('answer', formValue.answer);
    
    return await axios.post(Constant.URL + '/faq/store', bodyFormData, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('token')
      }
    })
    .then(res => {
      setIsLoading(false);
      setAction('list');
    })
    .catch(error => {
      setIsLoading(false);
      setShowAlert(true);
      setMessageError(error.message);
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const bodyRawData = {
      'question': formValue.question,
      'answer': formValue.answer,
    }

    return await axios.put(Constant.URL + '/faq/update/' + id, bodyRawData, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('token'),
      }
    })
    .then(res => {
      setIsLoading(false);
      setAction('list');
    })
    .catch(error => {
      setIsLoading(false);
      setShowAlert(true);
      setMessageError(error.message);
    });
  };

  return (
    <>
      {showAlert ? (
        <Dialog
          open={showAlert}
          onClose={() => setShowAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
          <DialogContent style={{minWidth: 300}}>
            <DialogContentText id="alert-dialog-description">
              {messageError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              onClick={() => setShowAlert(false)}
              className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
      ) : null}

      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Form FAQ {action}
            </h6>
            <div>
                <button
                  onClick={() => setAction('list')}
                  className="bg-red-500 text-white active:bg-res-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Back To List 
                </button>
                <button
                  onClick={(e) => action === 'add' ? handleStore(e) : handleUpdate(e)}
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  {!isLoading ? 'Submit' : <i className="fas fa-spin fa-spinner"></i>}
                </button>
            </div>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Question
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <textarea
                    onChange={(e) => handleChange(e)}
                    name="question"
                    value={formValue.question}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  ></textarea>
                </div>
              </div>
            </div>
            
            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Answer
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <textarea
                    onChange={(e) => handleChange(e)}
                    name="answer"
                    value={formValue.answer}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
