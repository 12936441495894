import React from 'react';

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

const AuthorizationContext  = React.createContext();

const Routes = () => {
  const token = localStorage.getItem('token');
  // console.log('TOKEN ' + token); 
  return (
    <AuthorizationContext.Provider value={token}>
      {token !== null ? <Admin /> : <Auth />}
    </AuthorizationContext.Provider>
  )
}

export default Routes;