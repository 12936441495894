import React from "react";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios-jsonp-pro';
import * as Constant from '../../helpers/Constant.js';

export default function Dashboard() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [member, setMember] = React.useState({});
  const [pawns, setPawn] = React.useState([]);

  const history = useHistory();
  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      return await axios.get(Constant.URL + '/profile-member', {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('tokenMember'),
        }
      })
      .then(res => {
        setIsLoading(false);
        setMember(res.data.data.member);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.message === 'Request failed with status code 401') {
          localStorage.clear();
          history.push("/member");
        }
      });
    }

    const getAllPawn = async () => {
      setIsLoading(true);
      return await axios.get(Constant.URL + '/pawn-all', {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('tokenMember'),
        }
      })
      .then(res => {
        console.log(res.data.data.pawn);
        setIsLoading(false);
        setPawn(res.data.data.pawn);
      })
      .catch(error => {
        setIsLoading(false);
      });
    }

    fetchData();
    getAllPawn();
  }, [history]);

  const formatedMonthName = (date) => {
    const  months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

    return months[date.getMonth()];
  }

  return (
    <>
      <div className="flex pt-16 h-full h-auto">
              
        <div className="w-full lg:w-12/12">

          <div className="w-full mb-3 bg-white px-4 py-4">
            <div className="mb-4">
              <label className="inline-flex items-center">
                <span className="ml-2 text-2xl font-bold">
                  Hi, {member.name}
                </span>
              </label>
              <br></br>
              <label className="inline-flex items-center">
                <span className="ml-2 text-sm text-base">
                  Mau gadai apa hari ini?
                </span>
              </label>
            </div>
          </div>

          <div className="w-full mb-3 bg-white px-4 py-4">
            <div className="mb-1">
              <label className="inline-flex items-center">
                <span className="ml-2 text-lg font-bold">
                  Gadai Baru
                </span>
              </label>
              <br></br>

              <Link className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                <div className="block justify-start">
                  <img
                    alt="arrow-right"
                    src={require("assets/img/icon/lm.png").default}
                    className="rounded-full mx-auto"
                    style={{width: 40}}
                  />
                </div>
                <h4 className="text-blueGray-500 ml-2">
                  <b>Logam Mulia Batangan</b>
                </h4>
                <div
                  className="flex flex-grow items-center bg-opacity-0 shadow-none block  list-none ml-auto"
                >
                  <ul className="flex flex-col lg:flex-row list-none ml-auto">

                    <li className="flex items-center">
                      <Link
                        className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                        target="_blank"
                      >
                        <img
                          alt="chevron_right"
                          src={require("assets/img/icon/chevron_right.png").default}
                          style={{width: 30}}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Link>
              <hr></hr>

              <Link className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                <div className="block justify-start">
                  <img
                    alt="arrow-right"
                    src={require("assets/img/icon/jewelry.png").default}
                    className="rounded-full mx-auto"
                    style={{width: 40}}
                  />
                </div>
                <h4 className="text-blueGray-500 ml-2">
                  <b>Perhiasan Emas</b>
                </h4>
                <div
                  className="flex flex-grow items-center bg-opacity-0 shadow-none block list-none ml-auto"
                >
                  <ul className="flex flex-col lg:flex-row list-none ml-auto">

                    <li className="flex items-center">
                      <Link
                        className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                        target="_blank"
                      >
                        <img
                          alt="chevron_right"
                          src={require("assets/img/icon/chevron_right.png").default}
                          style={{width: 30}}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Link>
              <hr></hr>

              <Link className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                <div className="block justify-start">
                  <img
                    alt="diamond"
                    src={require("assets/img/icon/diamond.png").default}
                    className="rounded-full mx-auto"
                    style={{width: 40}}
                  />
                </div>
                <h4 className="text-blueGray-500 ml-2">
                  <b>Perhiasan Berlian</b>
                </h4>
                <div
                  className="flex flex-grow items-center bg-opacity-0 shadow-none block list-none ml-auto"
                >
                  <ul className="flex flex-col lg:flex-row list-none ml-auto">

                    <li className="flex items-center">
                      <Link
                        className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                        target="_blank"
                      >
                        <img
                          alt="chevron_right"
                          src={require("assets/img/icon/chevron_right.png").default}
                          style={{width: 30}}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Link>
              <hr></hr>

              <Link className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                <div className="block justify-start">
                  <img
                    alt="diamond_ring"
                    src={require("assets/img/icon/diamond_ring.png").default}
                    className="rounded-full mx-auto"
                    style={{width: 40}}
                  />
                </div>
                <h4 className="text-blueGray-500 ml-2">
                  <b>Perhiasan Batu Permata Lainnya</b>
                </h4>
                <div
                  className="flex flex-grow items-center bg-opacity-0 shadow-none block list-none ml-auto"
                >
                  <ul className="flex flex-col lg:flex-row list-none ml-auto">

                    <li className="flex items-center">
                      <Link
                        className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                        target="_blank"
                      >
                        <img
                          alt="chevron_right"
                          src={require("assets/img/icon/chevron_right.png").default}
                          style={{width: 30}}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Link>

            </div>
          </div>

          <div className="w-full bg-white px-4 py-4">
            {/* <img
              alt="term_and_condition"
              src={require("assets/img/icon/term_and_conditions.png").default}
              style={{margin: 'auto'}}
            />

            <div className="text-center mb-4">
              <label className="inline-flex items-center">
                <span className="ml-2 text-sm text-base">
                  Belum ada detail gadai
                </span>
              </label>
            </div> */}

            <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
              <label className="inline-flex items-center">
                <span className="ml-2 text-lg font-bold">
                  Detail Gadai Saya
                </span>
              </label>
              <Link
                to="/"
                className="bg-gold text-white active:bg-gold text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              >
                Lihat Semua
              </Link>
            </div>

            {
              isLoading ? (
                <>
                  <tr>
                    <td colSpan="6" className="text-center">
                      <br></br>
                      <i className="fas fa-spin fa-spinner"></i>
                      <br></br>
                      <br></br>
                    </td>
                  </tr>
                </>
              ) : pawns.length < 1 ? (
                <>
                  <tr>
                      <td colSpan="6" className="text-center border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <br></br>
                        <span className={
                          "ml-3 text-blueGray-600"
                        }>No data available!</span>
                        <br></br>
                        <br></br>
                      </td>
                    </tr>
                </>
              ) : pawns.map((pawn) => {
                return (
                  <div className="w-full md:w-12/12">
                    <div className="relative flex flex-col min-w-0 break-words bg-blueGray-200 w-full rounded-lg">
                      <div className="px-6 py-6 flex-auto">
                        <h6 className="text-xl font-semibold mb-3">{pawn.application_number}</h6>
                        <hr></hr>
                        
                        <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                          <h4 className="text-blueGray-500">
                            Status Gadai
                          </h4>
                          <h4 className="text-blueGray-500">
                            <b>{pawn.status}</b>
                          </h4>
                        </div>

                        <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                          <h4 className="text-blueGray-500">
                            Jenis Barang
                          </h4>
                          <h4 className="text-blueGray-500">
                            <b>{pawn.pawn_item[0].pawn_item_type.text}</b>
                          </h4>
                        </div>

                        <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-3">
                          <h4 className="text-blueGray-500">
                            Total Pinjaman
                          </h4>
                          <h4 className="text-blueGray-500">
                            <b>Rp. {pawn.loan.toLocaleString(undefined, {maximumFractionDigits:2})}</b>
                          </h4>
                        </div>

                        <div className="mx-auto flex flex-wrap items-center justify-between mt-3 mb-6">
                          <h4 className="text-blueGray-500">
                            Tanggal Jatuh Tempo
                          </h4>
                          <h4 className="text-blueGray-500">
                            <b>{new Date(pawn.due_date).getDate()} {formatedMonthName(new Date(pawn.due_date))} {new Date(pawn.due_date).getFullYear()}</b>
                          </h4>
                        </div>
                      
                        <Link
                          to={"/member/detail/" + pawn.id}
                          className="w-full text-center bg-gold text-white active:bg-gold text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                          style={{display: 'inline-block'}}
                        >
                          Lihat Detail
                        </Link>

                      </div>
                    </div>
                  </div>
                )
              })
            }
            
          </div>

        </div>
      </div>
    </>
  );
}
