import React from "react";
import { Link, useHistory } from "react-router-dom";
import Carousel from 'react-material-ui-carousel';
// import { Paper, Button, } from '@material-ui/core'
// import { AutoRotatingCarousel } from 'material-auto-rotating-carousel';
// import { Slide } from 'material-auto-rotating-carousel';

// components

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

import Partner from '../components/Section/SectionPartner';

export default function LakuSimpan() {

  const history = useHistory();
  const handleOnClick = (e) => {
    localStorage.setItem('section', e);
    history.push('/');
  };

  // const { red, blue, green } = require('@material-ui/core/colors');

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    
    <>
      <AuthNavbar fixed />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url(" + require("assets/img/laku_simpan.png").default + ")",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute"
            ></span>
          </div>
      
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">

              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto mt-16">

                <div style={{padding: 30, backgroundColor: 'rgb(20, 19, 19, 0.5)', borderRadius: 10}}>
                  <h1 className="text-white font-semibold text-5xl">
                    Laku Simpan
                  </h1>
                  <hr className="mt-8"></hr>
                  <p className="mt-4 text-lg text-blueGray-200">
                    Laku Simpan merupakan layanan Lakuemas berupa penyimpanan emas dalam jangka waktu yang disepakati dengan imbal hasil berupa sewa modal yang tetap dalam wujud gramasi emas.
                  </p>
                  <br></br>
                  <Link
                    to="/simulation/1"
                    className="bg-gold text-white active:bg-gold text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    Simulasi Laku Simpan
                  </Link>
                </div>
                
              </div>

              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                </div>
              </div>

            </div>
          </div>

        </div>

        <section className="pb-20 pt-12 bg-blueGray-200">
          <div className="container mx-auto px-4">
            
            <div style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap'}}>
              <span
                className="get-started"
              >
                <img
                  alt="..."
                  src={require("assets/img/logo.png").default}
                  style={{width: 50}}
                />
              </span>
              <span>
                <img
                  alt="..."
                  src={require("assets/img/icon/close.png").default}
                />
              </span>
              <span>
                <img
                  alt="..."
                  src={require("assets/img/icon/logo_lakuemas_color.png").default}
                  style={{width: 50}}
                />
              </span>
            </div>

            <div className="flex flex-wrap items-center mt-12">
              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="w-full md:w-12/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-center inline-flex items-center justify-center mb-5">
                        <img
                          alt="..."
                          src={require("assets/img/icon/employee_id.png").default}
                        />
                      </div>
                      <h6 className="text-xl font-semibold">Dilayani oleh Relationship Manager</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Disamping menyimpan emas, Anda akan mendapatkan pelayanan eksklusif dari relationship manager kami
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="w-full md:w-12/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-center inline-flex items-center justify-center mb-5">
                        <img
                          alt="..."
                          src={require("assets/img/icon/finance_professional.png").default}
                        />
                      </div>
                      <h6 className="text-xl font-semibold">Imbal Hasil yang Kompetitif</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Nikmati penawaran imbal hasil<span style={{color: 'red'}}>*</span> yang kompetitif dalam tiga pilihan jangka waktu yang dapat diperpanjang di setiap akhir periode
                      </p>
                      <span style={{color: 'red', fontSize: 12}}>*mengikuti ketentuan Lakuemas</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="w-full md:w-12/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-center inline-flex items-center justify-center mb-5">
                        <img
                          alt="..."
                          src={require("assets/img/icon/black_friday_sale.png").default}
                        />
                      </div>
                      <h6 className="text-xl font-semibold">Dapatkan Berbagai Rewards</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Sebagai nasabah Laku Simpan, Anda akan mendapatkan berbagai rewards ekslusif dari merchant-merchant terkemuka
                        <br></br>
                        <br></br>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>



        <section className="pb-20 pt-12">
          <div className="container mx-auto px-4 justify-center text-center">

            <div className="w-full lg:w-12/12">
              <img
                alt="..."
                src={require("assets/img/icon/gift_box.png").default}
                style={{margin: 'auto'}}
              />
              <h2 className="text-4xl font-semibold">Manfaat Laku Simpan</h2>
              <p className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                Memperoleh rewards ekslusif khusus nasabah Laku Simpan pada beberapa
              </p>
            </div>

            <div className="flex flex-wrap items-center mt-12">

              <div className="w-full md:w-4/12 lg:w-3/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-white">
                  <img
                    alt="..."
                    src={require("assets/img/manfaat_1.png").default}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative mb-4">
                    <p className="text-md font-light mt-2 text-black">
                      Dining
                    </p>
                  </blockquote>
                </div>
              </div>

              <div className="w-full md:w-4/12 lg:w-3/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-white">
                  <img
                    alt="..."
                    src={require("assets/img/manfaat_2.png").default}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative mb-4">
                    <p className="text-md font-light mt-2 text-black">
                      Travel
                    </p>
                  </blockquote>
                </div>
              </div>

              <div className="w-full md:w-4/12 lg:w-3/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-white">
                  <img
                    alt="..."
                    src={require("assets/img/manfaat_3.png").default}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative mb-4">
                    <p className="text-md font-light mt-2 text-black">
                      Hotel
                    </p>
                  </blockquote>
                </div>
              </div>

              <div className="w-full md:w-4/12 lg:w-3/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-white">
                  <img
                    alt="..."
                    src={require("assets/img/manfaat_4.png").default}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative mb-4">
                    <p className="text-md font-light mt-2 text-black">
                      Healthcare
                    </p>
                  </blockquote>
                </div>
              </div>
              
            </div>
          </div>
        </section>


        <section className="relative py-20">
          <div className="container mx-auto px-4">

            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-12/12 px-4">
                <img
                  alt="..."
                  src={require("assets/img/icon/3_golds.png").default}
                  style={{margin: 'auto'}}
                />
                <h2 className="text-4xl font-semibold">Skema Laku Simpan</h2>
              </div>
            </div>

            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-6/12 ml-auto mr-auto">

                <Carousel
                  indicators={false}
                  navButtonsAlwaysVisible={true}
                  // style={'slide'}
                >
                  {/* {
                    items.map(
                      (item, i) => "<Item key={i} item={item} />"
                    )
                  } */}
                  <div style={{textAlign: 'center'}}>
                    <img
                      alt="lakusimpan-homepage-empty-state"
                      src={require("assets/img/icon/1mobile.png").default}
                      style={{margin: 'auto'}}
                    />
                    <b>1. Buka fitur Laku Simpan di aplikasi Lakuemas</b>
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <img
                      alt="isi-detail"
                      src={require("assets/img/icon/2mobile.png").default}
                      style={{margin: 'auto'}}
                    />
                    <br></br>
                    <br></br>
                    <b>2. Isi jumlah emas dan jangka waktu titipan</b>
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <img
                      alt="pilih-perpanjangan"
                      src={require("assets/img/icon/3mobile.png").default}
                      style={{margin: 'auto'}}
                    />
                    <br></br>
                    <br></br>
                    <b>3. Pilih jenis perpanjangan</b>
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <img
                      alt="konfirmasi"
                      src={require("assets/img/icon/4mobile.png").default}
                      style={{margin: 'auto'}}
                    />
                    <br></br>
                    <br></br>
                    <b>4. Periksan kembali detail dan konfirmasi</b>
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <img
                      alt="tnc"
                      src={require("assets/img/icon/5mobile.png").default}
                      style={{margin: 'auto'}}
                    />
                    <b>5. Menyetujui syarat dan ketentuan</b>
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <img
                      alt="pending"
                      src={require("assets/img/icon/6mobile.png").default}
                      style={{margin: 'auto'}}
                    />
                    <b>6. Menunggu simpanan emas disetujui</b>
                  </div>
                  <div style={{textAlign: 'center'}}>
                  <img
                    alt="pop-up-disetujui"
                    src={require("assets/img/icon/7mobile.png").default}
                    style={{margin: 'auto'}}
                  />
                  <b>7. Simpanan emas telah disetujui</b>
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <img
                      alt="approved"
                      src={require("assets/img/icon/8mobile.png").default}
                      style={{margin: 'auto'}}
                    />
                    <b>8. Simpanan emas telah aktif</b>
                  </div>
                </Carousel>

                {/* <AutoRotatingCarousel
                  label='Get started'
                  open={false}
                  onClose={() => setState({ open: false })}
                  onStart={() => setState({ open: false })}
                  style={{ position: 'static' }}
                >
                  <Slide
                    media={<img src='http://www.icons101.com/icon_png/size_256/id_79394/youtube.png' />}
                    mediaBackgroundStyle={{ backgroundColor: red[400] }}
                    style={{ backgroundColor: red[600] }}
                    title='This is a very cool feature'
                    subtitle='Just using this will blow your mind.'
                  />
                  <Slide
                    media={<img src='http://www.icons101.com/icon_png/size_256/id_80975/GoogleInbox.png' />}
                    mediaBackgroundStyle={{ backgroundColor: blue[400] }}
                    style={{ backgroundColor: blue[600] }}
                    title='Ever wanted to be popular?'
                    subtitle='Well just mix two colors and your are good to go!'
                  />
                  <Slide
                    media={<img src='http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png' />}
                    mediaBackgroundStyle={{ backgroundColor: green[400] }}
                    style={{ backgroundColor: green[600] }}
                    title='May the force be with you'
                    subtitle='The Force is a metaphysical and ubiquitous power in the Star Wars fictional universe.'
                  />
                </AutoRotatingCarousel> */}

              </div>

              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default}
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Install aplikasi Lakuemas lalu pilih menu Laku Simpan - pilih besaran gramasi - pilih jangka waktu menyimpan - baca persetujuan pelanggan - lalu pilih ajukan. Atau hubungi butik Lakuemas, informasi lokasi butik dapat dilihat di sini atau hubungi kami di nomor +6281211855436
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Jangka waktu penyimpanan adalah 4, 8 dan 12 bulan dan dapat diperpanjang sesuai kebutuhan.
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Membawa barang jaminan dan dokumen yang menyertai seperti invoice dan sertifikat
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Lakuemas berhak untuk menyetujui atau menunda pengajuan simpanan berdasarkan ketersediaan peluang pendanaan.
                          </h4>
                        </div>
                      </div>
                    </li>

                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Dana simpanan akan disalurkan lewat Koperasi Simpan Pinjam Lakuemas Indonesia (KSP LEI) sebagai pengelola dana simpanan.
                          </h4>
                        </div>
                      </div>
                    </li>

                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            KSP bekerja sama dengan Peer to Peer Lending (P2P) independen terpercaya yang telah terdaftar di Otoritas Jasa Keuangan.
                          </h4>
                        </div>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="relative py-20 bg-blueGray-200">
          <div className="container mx-auto px-4">

            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-12/12 px-4">
                <img
                  alt="tnc"
                  src={require("assets/img/icon/term_and_condition.png").default}
                  style={{margin: 'auto'}}
                />
                <h2 className="text-4xl font-semibold">Syarat Ketentuan Laku Simpan</h2>
              </div>
            </div>

            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-6/12 ml-auto mr-auto">
                <img
                  alt="tnc"
                  className="max-w-full rounded-lg shadow-lg"
                  src={require("assets/img/tnc.png").default}
                />
              </div>
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="..."
                            src={require("assets/img/icon/arrow_right.png").default}
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Pelanggan yang mengikuti program Laku Simpan telah melakukan pendaftaran dan mengajukan program Laku Simpan
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="..."
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Pelanggan memberikan kuasa kepada Lakuemas untuk mengalokasikan dana terhadap pinjaman-pinjaman produktif.
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="..."
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            User Laku Simpan akan secara otomatis terdaftar sebagai anggota Koperasi Simpan Pinjam Laku Emas Indonesia (KSP LEI) dengan kewajiban berupa:
                          </h4>
                        </div>
                      </div>
                      <br></br>
                      <ul style={{marginLeft: 50}}>
                        <li className="text-blueGray-500" style={{listStyle: 'inside'}}>Simpanan Pokok, sebesar Rp 50.000 (sekali pembayaran)</li>
                        <li className="text-blueGray-500" style={{listStyle: 'inside'}}>Simpanan Wajib, sebesar Rp 50.000 perbulan (sepanjang jangka waktu penyimpanan)</li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-16 bg-white relative pt-32">

          <div className="container mx-auto">
            <div className="flex flex-wrap justify-center bg-white rounded-lg px-12 relative z-10">
              <div className="w-full text-center lg:w-8/12">
                <h3 className="font-semibold text-3xl">
                  Yuk, simpan emas Anda sekarang!
                </h3>
                <div className="sm:block flex flex-col mt-10">
                  <Link
                    to="/simulation/1"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-gold active:bg-gold uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Simulasi Laku Simpan
                  </Link>
                  <Link
                    // to="/"
                    className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gold active:bg-gold uppercase text-sm shadow hover:shadow-lg"
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnClick('contact-section');
                    }}
                  >
                    <span>Hubungi Kami</span>
                  </Link>
                </div>
                <div className="text-center mt-12">
                  <p className="font-semibold">Download aplikasi Lakuemas</p>
                </div>
                <br></br>                    
                <div style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap'}}>
                  <a
                    href="https://apps.apple.com/id/app/lakuemas/id1401130641"
                    target="_blank"
                    rel="noreferrer"
                    className="get-started"
                  >
                    <img
                      alt="app-store"
                      src={require("assets/img/icon/download_on_app_store.png").default} 
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.lakuemas.app"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="play-store"
                      src={require("assets/img/icon/get_it_on_google_play.png").default} 
                    />
                  </a>
                </div>
                
              </div>
            </div>
          </div>
        </section>
        
        <Partner />
      </main>
      <Footer />
    </>
  );
}
