import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Link, Switch, Route, Redirect, useHistory, withRouter } from "react-router-dom";
import axios from 'axios-jsonp-pro';
import * as Constant from '../helpers/Constant.js';

// components

// import Navbar from "components/Navbars/AuthNavbar.js";
// import FooterSmall from "components/Footers/FooterSmall.js";

// views
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

export default function Auth() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [formValue, setformValue] = React.useState({
    username: '',
    password: ''
  });

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value
    })
  }

  const history = useHistory();
  const handleLogin = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append('username', formValue.username);
    bodyFormData.append('password', formValue.password);
    
    return await axios.post(Constant.URL + '/login', bodyFormData)
    .then(res => {
      localStorage.setItem('isLoggedin', true);
      localStorage.setItem('token', res.data.token);
      // localStorage.setItem('user', JSON.stringify(res.data.data));
      history.push("/admin");
    })
    .catch(error => {
      setIsLoading(false);
      setShowAlert(true);
      setMessageError(error.message);
    });
  };

  return (
    <>
      {/* <Navbar transparent /> */}
      <main>
        <section className="relative w-full h-full bg-blueGray-800 py-40 min-h-screen">
          {/* <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          > */}

            <div className="container mx-auto px-4 h-full">
              <div className="flex content-center items-center justify-center h-full">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                    <div className="flex-auto px-4 lg:px-10 py-10 pt-10">
                      <form>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Username
                          </label>
                          <input
                            onChange={(e) => handleChange(e)}
                            type="username"
                            name="username"
                            value={formValue.username}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Username"
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Password
                          </label>
                          <input
                            onChange={(e) => handleChange(e)}
                            type="password"
                            name="password"
                            value={formValue.password}
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Password"
                          />
                        </div>
                        <div>
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              id="customCheckLogin"
                              type="checkbox"
                              className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                            />
                            <span className="ml-2 text-sm font-semibold text-blueGray-600">
                              Remember me
                            </span>
                          </label>
                        </div>

                        {showAlert ? (
                          <Dialog
                            open={showAlert}
                            onClose={() => setShowAlert(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                            <DialogContent style={{minWidth: 300}}>
                              <DialogContentText id="alert-dialog-description">
                               {messageError}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={() => setShowAlert(false)} color="primary">
                                Close
                              </Button>
                            </DialogActions>
                          </Dialog>
                        ) : null}

                        <div className="text-center mt-6">
                          <button
                            onClick={(e) => handleLogin(e)}
                            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                            type="button"
                          >
                            {!isLoading ? 'Sign In' : <i className="fas fa-spin fa-spinner"></i>}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  
                  <div className="flex flex-wrap mt-6 relative">
                    <div className="w-1/2">
                      <Link
                        to="/"
                        // onClick={(e) => e.preventDefault()}
                        className="text-blueGray-200"
                      >
                        <small>Back to website</small>
                      </Link>
                    </div>
                    {/* <div className="w-1/2 text-right">
                      <Link to="/auth/register" className="text-blueGray-200">
                        <small>Forgot password?</small>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

          {/* </div> */}
          <Switch>
            <Route path="/auth/login" exact component={withRouter(Login)} />
            <Route path="/auth/register" exact component={withRouter(Register)} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          {/* <FooterSmall absolute /> */}
        </section>
      </main>
    </>
  );
}
