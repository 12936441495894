import React from "react";
import axios from 'axios-jsonp-pro';
import * as Constant from "../../helpers/Constant";

// components

export default function SectionPartner() {
  const [partners, setPartners] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      return await axios.get(Constant.BASEURL + 'partner')
      .then(res => {
        // console.log(res.data.data.partner);
        setPartners(res.data.data.partner);
      })
      .catch(error => {
        console.log(error.message);
      });
    }
    fetchData();
  }, []);

  return (
    <>
      <section className="relative py-20">

          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
              "url(" + require("assets/img/gold_bg.png").default + ")",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute"
            ></span>
          </div>

          <div className=" container mx-auto px-4">
            
            <div className="flex flex-wrap">
              {
                partners.map(( key, index ) => {
                  return (
                    <div key={index} className="w-full md:w-2/12 lg:w-2/12 lg:mb-0 mb-12 px-4">
                      <div className="px-6">
                        {
                         key.title === 'Lakuemas' ? (
                          <a
                            href="https://www.lakuemas.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              alt={key.title}
                              src={Constant.BASEURL + key.image}
                              style={{margin: 'auto'}}
                              className="image-hover"
                            />
                          </a>
                         ) : (
                          <img
                            alt={key.title}
                            src={Constant.BASEURL + key.image}
                            style={{margin: 'auto'}}
                            className="image-hover"
                          />
                         )
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </section>
    </>
  );
}
