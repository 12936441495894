import React from "react";
import axios from 'axios-jsonp-pro';
import * as Constant from '../../helpers/Constant.js';
import { useHistory } from "react-router-dom";

import UserDropdown from "components/Dropdowns/UserDropdown.js";

export default function Navbar() {
  const [user, setUser] = React.useState({});
  
  const history = useHistory();
  React.useEffect(() => {
    const fetchData = async () => {
      return await axios.get(Constant.URL + '/profile', {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('token'),
        }
      })
      .then(res => {
        setUser(res.data.data.user);
      })
      .catch(error => {
        if (error.message === 'Request failed with status code 401') {
          localStorage.clear();
          history.push("/admin");
        }
      });
    }
    fetchData();
  },[history]);
  
  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="/admin"
            onClick={(e) => e.preventDefault()}
          >
            Hi, {user.name}
          </a>
          {/* Form */}
          <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
            <div className="relative flex w-full flex-wrap items-stretch">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-search"></i>
              </span>
              <input
                type="text"
                placeholder="Search here..."
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
              />
            </div>
          </form>
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
