/*eslint-disable*/
import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
// components

// import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function MobileAuthNavbar() {
  const token = localStorage.getItem('tokenMember');

  const [backgroundColor, setBackgroundColor] = React.useState('bg-black-100');
  const [textColor, setTextColor] = React.useState('text-white');
  const { id } = useParams();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  const history = useHistory();
  const handleOnClick = (e) => {
    localStorage.setItem('section', e);
    history.push('/');
  };

  React.useEffect( () => {
    setTimeout(
      () => setOpen(false), 
      1000
    );

    const handleScroll = _ => { 
      if (window.pageYOffset > 1) {
        setBackgroundColor('bg-white shadow');
        setTextColor('text-blueGray-700');
      } else {
        setBackgroundColor('bg-black-100');
        setTextColor('text-white');
      }
    }
    window.addEventListener('scroll', handleScroll)
    return _ => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  
  const [visible, setVisible] = React.useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <>
      <nav className="top-0 container-mobile fixed z-50 w-12/12 px-2 py-4 bg-white shadow">
        <div className="px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="fixed flex justify-between w-auto static block justify-start">
            <Link
            to="/"
            className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap"
            >
            <img
                alt="arrow_back"
                src={require("assets/img/icon/arrow_back.png").default}
                style={{width: 30}}
            />
            </Link>
          </div>
          <div className="flex flex-row ml-auto block static">
            <div
                className="text-blueGray-700 text-sm font-bold inline-block mr-4 whitespace-nowrap"
            >
                <img
                alt="identification_card"
                src={require("assets/img/icon/identification_card.png").default}
                style={{width: 30}}
                />
            </div>
          </div>

        </div>
      </nav>
      
      {
        visible ? <div 
          className="shadow hover:shadow-lg"
          style={{
            position: 'fixed',
            width: 50,
            right: 30,
            bottom: 30,
            height: 50,
            zIndex: 100,
            cursor: 'pointer',
            backgroundColor: '#e0bf4b',
            borderRadius: 25,
            color: 'white',
            textAlign: 'center',
          }} 
          onClick={() => scrollToTop()}
        ><i className="fas fa-arrow-up mt-2 text-xl"></i></div> : null
      }
      
    </>
  );
}
