import { Link } from "react-router-dom";
import React from "react";

// components

export default function SectionHome() {
  return (
    <>
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px" id="home-section">

        <div
          className="absolute top-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage:
              "url(" + require("assets/img/gold_tunai.png").default + ")",
          }}
        >
          <span
            // id="blackOverlay"
            className="w-full h-full absolute"
          ></span>
        </div>

        <div className="container relative mx-auto items-center flex flex-wrap">
          
          <div className="items-center flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto mt-16">
              <div style={{padding: 30, backgroundColor: 'rgb(20, 19, 19, 0.5)', borderRadius: 10}}>
                <h1 className="text-white font-semibold text-5xl">
                  Laku Gadai
                </h1>
                <hr className="mt-8"></hr>
                <p className="mt-4 text-lg text-blueGray-200">
                  Laku Gadai merupakan produk pinjaman dengan sistem gadai dengan barang jaminan berupa emas maupun berlian untuk memenuhi kebutuhan pelanggan memperoleh dana dengan cepat.
                </p>
                <br></br>
                {/*
                <Link
                  to="/simulation/0"
                  className="bg-gold text-white active:bg-gold text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                >
                  Simulasi Gadai Emas
                </Link>
                */}
              </div>
            </div>

            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div className="pr-12">
              </div>
            </div>

          </div>

          {/* <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-42 sm:pt-0">
              <h2 className="font-semibold text-5xl text-black">
                Solusi Emas Untuk Semua
              </h2>
              <p className="mt-4 text-lg leading-relaxed text-black">
                Anda bisa gadai, simpan atau jual emas Anda pada kami. Solusi terbaik dalam satu tempat.
              </p>
              <div className="mt-12">
                <Link
                  to="/simulation/0"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gold active:bg-gold uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Simulasi Emas
                </Link>
                <br></br>
                <br></br>
                <Link onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("advantages-section").scrollIntoView({ behavior: "smooth" });
                }}>
                  <p className="mt-4 text-lg leading-relaxed text-black" style={{float: 'left', marginRight: 10}}>
                    Pelajari lebih lanjut
                  </p>
                  <img
                    src={require("assets/img/icon/arrow_downward.png").default}
                    alt="arrow-downward"
                    style={{marginTop: 19}}
                  />
                </Link>
              </div>
            </div>
          </div> */}


        </div>

        {/* <img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-12 sm:mt-0 w-10/12"
          src={require("assets/img/pattern_header.png").default}
          alt="gold-hand"
          style={{zIndex: '-1'}}
        /> */}

      </section>
    </>
  );
}
