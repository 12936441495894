/*eslint-disable*/

import { Link, useHistory, useParams } from "react-router-dom";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProductDropdown from "components/Dropdowns/ProductDropdown";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';

// components

// import IndexDropdown from "components/Dropdowns/IndexDropdown.js";




// import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Navbar() {
  const [backgroundColor, setBackgroundColor] = React.useState('bg-black-100');
  const [textColor, setTextColor] = React.useState('text-white');
  const { id } = useParams();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  const history = useHistory();
  const handleOnClick = (e) => {
    localStorage.setItem('section', e);
    history.push('/');
  };

  React.useEffect( () => {
    setTimeout(
      () => setOpen(false), 
      1000
    );

    const handleScroll = _ => { 
      if (window.pageYOffset > 1) {
        setBackgroundColor('bg-white shadow');
        setTextColor('text-blueGray-700');
      } else {
        setBackgroundColor('bg-black-100');
        setTextColor('text-white');
      }
    }
    window.addEventListener('scroll', handleScroll)
    return _ => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  
  const [visible, setVisible] = React.useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <>
      <div>
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      <nav className={"top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg " + backgroundColor}>
      {/* <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow"> */}
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
            >
              {/* PT Sentral Gadai Kencana */}
              <img
                alt="SGK"
                src={require("assets/img/logo.png").default}
                style={{width: 50}}
              />
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {/* <i className="fas fa-bars"></i> */}
              <i className={"fas fa-bars " + textColor}></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center text-blueGray-700 hover:text-gold">
                <Link
                  // to="/"
                  className={textColor + " hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.pathname === '/simulation/' + id ? handleOnClick('home-section') : document.getElementById("home-section").scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Beranda
                </Link>
              </li>

              {/* <li className="flex items-center">
                <Link
                  className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.pathname === '/simulation' ? handleOnClick() : document
                      .getElementById("product-section")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Produk
                </Link>
              </li> */}

              
              {/* <li className="flex items-center">
                <ProductDropdown />
              </li> */}
              {/*
              <li className="flex items-center text-blueGray-700 hover:text-gold">
                <Link
                  to="/simulation/0"
                  // className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                  className={textColor + " hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                >
                  Simulasi Gadai
                </Link>
              </li>
              */}

              {/* <li className="flex items-center text-blueGray-700 hover:text-gold">
                <Link
                  // to="/news"
                  // className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                  className={textColor + " hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.pathname === '/simulation/' + id ? handleOnClick('news-section') : document
                      .getElementById("news-section")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Berita
                </Link>
              </li> */}

              <li className="flex items-center">
                <Link
                  // to="/about"
                  // className="text-blueGray-700 hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                  className={textColor + " hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.pathname === '/simulation/' + id ? handleOnClick('about-section') : document
                      .getElementById("about-section")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Tentang Kami
                </Link>
              </li>

              <li className="flex items-center">
                <Link
                  // to="/contact"
                  // className="text-blueGray-700 hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                  className={textColor + " hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.pathname === '/simulation/' + id ? handleOnClick('contact-section') : document
                      .getElementById("contact-section")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Hubungi Kami
                </Link>
              </li>
              {/* <li className="flex items-center">
                <IndexDropdown />
              </li> */}
              {/* <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdemos.creative-tim.com%2Fnotus-react%2F%23%2F"
                  target="_blank"
                >
                  <i className="text-blueGray-400 fab fa-facebook text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Share</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fdemos.creative-tim.com%2Fnotus-react%2F%23%2F&text=Start%20your%20development%20with%20a%20Free%20Tailwind%20CSS%20and%20React%20UI%20Kit%20and%20Admin.%20Let%20Notus%20React%20amaze%20you%20with%20its%20cool%20features%20and%20build%20tools%20and%20get%20your%20project%20to%20a%20whole%20new%20level.%20"
                  target="_blank"
                >
                  <i className="text-blueGray-400 fab fa-twitter text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Tweet</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://github.com/creativetimofficial/notus-react?ref=nr-index-navbar"
                  target="_blank"
                >
                  <i className="text-blueGray-400 fab fa-github text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Star</span>
                </a>
              </li> */}

              {/* <li className="flex items-center">
                <Link
                  to="/member"
                  className="bg-gold text-white active:bg-gold text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                >
                   Masuk / Daftar
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      
      {
        visible ? <div 
          className="shadow hover:shadow-lg"
          style={{
            position: 'fixed',
            width: 50,
            right: 30,
            bottom: 30,
            height: 50,
            zIndex: 100,
            cursor: 'pointer',
            backgroundColor: '#e0bf4b',
            borderRadius: 25,
            color: 'white',
            textAlign: 'center',
          }} 
          onClick={() => scrollToTop()}
        ><i className="fas fa-arrow-up mt-2 text-xl"></i></div> : null
      }
      
    </>
  );
}
