import React from "react";
import { Link, useHistory } from "react-router-dom";
// import ReactWhatsapp from 'react-whatsapp';

// components

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import Partner from 'components/Section/SectionPartner';

const Accordion = withStyles({
  root: {
    backgroundColor: '#E2E8F0',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    borderRadius: 30,
    backgroundColor: 'rgba(255, 255, 255)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: 20,
    minHeight: 56,
    '&$expanded': {
      backgroundColor: '#FFB200',
      color: 'white',
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: '#E2E8F0',
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    flexBasis: '100%',
    flexShrink: 0,
    fontWeight: 'bold'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ButikLakuemas() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const history = useHistory();
  const handleOnClick = (e) => {
    localStorage.setItem('section', e);
    history.push('/');
  };

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <AuthNavbar fixed />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
              "url(" + require("assets/img/sgk_butik.png").default + ")",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute"
            ></span>
          </div>
      
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">

              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto mt-16">
                <div style={{padding: 30, backgroundColor: 'rgb(20, 19, 19, 0.5)', borderRadius: 10}}>
                  <h1 className="text-white font-semibold text-5xl">
                    Butik Lakuemas
                  </h1>
                  <hr className="mt-8"></hr>
                  <p className="mt-4 text-lg text-blueGray-200">
                    Butik Lakuemas adalah layanan yang menerima penjualan emas Anda dalam kondisi apapun (emas batangan, perhiasan, emas patah, rusak dan tidak ada surat) dengan harga yang transparan dan selalu update setiap hari.
                  </p>
                  <br></br>
                  <Link
                    to="/simulation/2"
                    className="bg-gold text-white active:bg-gold text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    Simulasi Jual Emas
                  </Link>
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                </div>
              </div>

            </div>
          </div>

        </div>

        <section className="pb-20 pt-12 bg-blueGray-200">
          <div className="container mx-auto px-4">
            
          <div style={{justifyContent: 'center', display: 'flex', flexWrap: 'wrap'}}>
              <span
                className="get-started"
              >
                <img
                  alt="logo-sgk"
                  src={require("assets/img/logo.png").default}
                  style={{width: 50}}
                />
              </span>
              <span>
                <img
                  alt="close"
                  src={require("assets/img/icon/close.png").default}
                />
              </span>
              <span>
                <img
                  alt="logo-butik-lakuemas"
                  src={require("assets/img/icon/logo_butik_lakuemas.png").default}
                  style={{width: 110}}
                />
              </span>
            </div>

            <div className="flex flex-wrap items-center mt-12">
              <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">
                <div className="w-full md:w-12/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-center inline-flex items-center justify-center mb-5">
                        <img
                          alt="video-conferencing"
                          src={require("assets/img/icon/video_conferencing.png").default}
                        />
                      </div>
                      <h6 className="text-xl font-semibold">Transparan</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Setiap analisa yang dilakukan untuk berat maupun kadar akan dikonfirmasikan langsung kepada pelanggan
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">
                <div className="w-full md:w-12/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-center inline-flex items-center justify-center mb-5">
                        <img
                          alt="remote-working-challages"
                          src={require("assets/img/icon/remote_working_challenges.png").default}
                        />
                      </div>
                      <h6 className="text-xl font-semibold">Fleksibel</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Anda dapat melakukan penjualan dari rumah
                        <br></br>
                        <br></br>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">
                <div className="w-full md:w-12/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-center inline-flex items-center justify-center mb-5">
                        <img
                          alt="finance-professional"
                          src={require("assets/img/icon/finance_professional.png").default}
                        />
                      </div>
                      <h6 className="text-xl font-semibold">Profesional</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Emas Anda akan dicek menggunakan alat yang canggih dan tenaga penaksir yang berpengalaman
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">
                <div className="w-full md:w-12/12 px-4 text-center">
                  <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-center inline-flex items-center justify-center mb-5">
                        <img
                          alt="investment"
                          src={require("assets/img/icon/investment.png").default}
                        />
                      </div>
                      <h6 className="text-xl font-semibold">Harga Terbaik</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Anda akan mendapatkan harga terbaik dari kami, dan pembaharuan harga dilakukan 2 kali dalam sehari
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>


        <div className="relative flex content-center items-center justify-center min-h-screen-100">
          <div className="w-full lg:w-12/12">
            <img
              alt="banner-butik"
              src={require("assets/img/banner_butik.png").default}
              style={{margin: 'auto', width: '100%'}}
            />
          </div>
        </div>

        <section className="relative py-20">

          <div className="container mx-auto px-4">

            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-12/12 px-4">
                <img
                  alt="term-and-condition"
                  src={require("assets/img/icon/term_and_condition.png").default}
                  style={{margin: 'auto'}}
                />
                <h2 className="text-4xl font-semibold">Syarat dan Ketentuan Butik Lakuemas</h2>
              </div>
            </div>

            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-6/12 ml-auto mr-auto">
                <img
                  alt="tnc"
                  className="max-w-full rounded-lg shadow-lg"
                  src={require("assets/img/tnc.png").default}
                />
              </div>
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default}
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Setiap customer yang melakukan transaksi <b>wajib menunjukkan identitas KTP/SIM/PASPOR</b>
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Untuk anak usia dibawah 17 tahun <b>dilarang menjual tanpa ada surat keterangan dari orang tua</b>
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Barang yang telah dijual <b>tidak dapat dikembalikan</b>
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Butik Lakuemas <b>tidak menerima barang illegal.</b> Jika ketahuan di kemudian hari, Butik Lakuemas <b>tidak ikut terlibat dalam segala urusan hukum</b>
                          </h4>
                        </div>
                      </div>
                    </li>

                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Ketika customer terbukti menjual barang illegal ke Butik Lakuemas dan menyebabkan Butik Lakuemas terlibat kasus hukum atau sesuatu yang merugikan Butik Lakuemas, <b>maka kami akan melakukan gugatan secara hukum kepada customer</b>
                          </h4>
                        </div>
                      </div>
                    </li>

                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Pengecekan sampel dikenakan <b>Rp. 200.000/ sampel</b> jika tidak jadi menjual
                          </h4>
                        </div>
                      </div>
                    </li>

                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <img
                            alt="arrow-right"
                            src={require("assets/img/icon/arrow_right.png").default} 
                            className="rounded-full mx-auto max-w-120-px"
                          />
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Emas dengan kadar <b>di bawah 10 karat (41.67%) tidak kami beli (kecuali ILY GOLD)</b>
                          </h4>
                        </div>
                      </div>
                    </li>


                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="relative py-20 bg-blueGray-200">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-12/12 px-4">
                <img
                  alt="term-and-condition"
                  src={require("assets/img/icon/term_and_condition.png").default}
                  style={{margin: 'auto'}}
                />
                <h2 className="text-4xl font-semibold">Paling Sering Ditanyakan</h2>
              </div>
            </div>

            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-6/12 ml-auto mr-auto">
                <img
                  alt="tnc-2"
                  className="max-w-full rounded-lg shadow-lg"
                  src={require("assets/img/tnc_2.png").default}
                />
              </div>
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">


                  <div className={classes.root}>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="bg-gold"
                      >
                        <Typography className={classes.heading}>
                          Apa itu Butik Lakuemas?
                        </Typography> 
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Butik Lakuemas adalah layanan Lakuemas yang siap menerima penjualan emas Anda dalam bentuk apapun (emas batangan, perhiasan, emas patah, rusak, tidak ada surat) dengan berat kurang dari 1 gram.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography className={classes.heading}>
                          Bagaimana cara menjual emas?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Untuk saat ini, kami hanya menerima penjualan emas secara langsung (reservasi terlebih dahulu). Untuk reservasi Anda dapat menghubungi Customer service kami via WA 081574303638
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                      >
                        <Typography className={classes.heading}>
                          Apa harga di website akurat?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Harga di Website kami akurat, harga tersebut diperbaharui setiap 2 kali sehari. Hal tersebut agar Anda mendapatkan harga emas terbaik.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                      >
                        <Typography className={classes.heading}>
                          Bagaimana jika saya berada di luar kota?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Anda tetap bisa menjual emas anda walaupun berada di luar kota. Untuk detailnya Anda dapat menghubungi Customer Service via WA 081574303638
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                      <AccordionSummary
                        expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                      >
                        <Typography className={classes.heading}>
                          Ada pertanyaan lain?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Jika anda memiliki pertanyaan lain, silahkan hubungi Customer Service kami via WA 081574303638 & Email butik@lakuemas.com
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <br></br>
                    <br></br>
                    <p >Pertanyaanmu belum terjawab? Hubungi kami via:</p>
                    <br></br>
                    {/* <ReactWhatsapp number="+6281574303638" message="Hello World!!!" /> */}
                    <img
                      alt="whatsapp"
                      src={require("assets/img/icon/whatsapp.png").default}
                      style={{float: 'left', marginRight: 10}}
                    />
                    <b>Whatsapp 081574303638</b>
                    <br></br>
                    <img
                      alt="mail-outline"
                      src={require("assets/img/icon/mail_outline.png").default}
                      style={{float: 'left', marginRight: 10}}
                    />
                    <b>butik@lakuemas.com</b>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-16 bg-white relative pt-32">

          <div className="container mx-auto">
            <div className="flex flex-wrap justify-center bg-white rounded-lg px-12 relative z-10">
              <div className="w-full text-center lg:w-8/12">
                <h3 className="font-semibold text-3xl">
                  Yuk, jual emas Anda sekarang!
                </h3>
                <div className="sm:block flex flex-col mt-10">
                  <Link
                    to="/simulation/2"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-gold active:bg-gold uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Simulasi Jual Emas
                  </Link>
                  <Link
                    // to="/"
                    className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gold active:bg-gold uppercase text-sm shadow hover:shadow-lg"
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnClick('contact-section');
                    }}
                  >
                    <span>Hubungi Kami</span>
                  </Link>
                </div>
                <div className="text-center mt-16"></div>
              </div>
            </div>
          </div>
        </section>
        
        <Partner />
      </main>
      <Footer />
    </>
  );
}
