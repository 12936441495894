/*eslint-disable*/
import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

// components

// import PagesDropdown from "components/Dropdowns/PagesDropdown.js";

export default function Navbar(props) {
  // const { id } = useParams();
  
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [navbarOpen, setNavbarOpen] = React.useState(false);
  // const [backgroundColor, setBackgroundColor] = React.useState('bg-black-100');
  // const [textColor, setTextColor] = React.useState('text-white');

  const history = useHistory();
  const handleOnClick = (e) => {
    localStorage.setItem('section', e);
    history.push('/');
  };

  React.useEffect(() => {
    setTimeout(
      () => setOpen(false), 
      1000
    );

    // const handleScroll = _ => { 
    //   if (window.pageYOffset > 1) {
    //     setBackgroundColor('bg-white shadow');
    //     setTextColor('text-blueGray-700');
    //   } else {
    //     setBackgroundColor('bg-black-100');
    //     setTextColor('text-white');
    //   }
    // }
    // window.addEventListener('scroll', handleScroll)
    // return _ => {
    //   window.removeEventListener('scroll', handleScroll)
    // }
  }, []);

  const [visible, setVisible] = React.useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <>
      <div>
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      {/* <nav className={"top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg " + backgroundColor}>  */}
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
              to="/"
            >
              {/* PT Sentral Gadai Kencana */}
              <img
                alt="logo-sgk"
                src={require("assets/img/logo.png").default}
                style={{width: 50}}
              />
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {/* <i className={"fas fa-bars " + textColor}></i> */}
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <Link
                  // to="/"
                  // className={textColor + " hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                  className="text-sm font-bold hover:text-gold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOnClick('home-section')
                  }}
                >
                  Beranda
                </Link>
              </li>

              {/* <li className="flex items-center">
                <Link
                  to="/product"
                  className={textColor + " text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                >
                  Produk
                </Link>
              </li> */}

              {/* <li className="flex items-center">
                <ProductDropdown textColor={textColor}/>
              </li> */}

              <li className="flex items-center">
                <Link
                  to="/simulation/0"
                  // className={textColor + " hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                  className="text-sm font-bold hover:text-gold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                >
                  Simulasi Gadai
                </Link>
              </li>

              {/* <li className="flex items-center">
                <Link
                  // to="/"
                  // className={textColor + " hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                  className="text-sm font-bold hover:text-gold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOnClick('news-section')
                  }}
                >
                  Berita
                </Link>
              </li> */}

              <li className="flex items-center">
                <Link
                  // to="/"
                  // className={textColor + " hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                  className="text-sm font-bold hover:text-gold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOnClick('about-section')
                  }}
                >
                  Tentang Kami
                </Link>
              </li>

              <li className="flex items-center">
                <Link
                  // to="/"
                  // className={textColor + " hover:text-gold text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"}
                  className="text-sm font-bold hover:text-gold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOnClick('contact-section')
                  }}
                >
                  Hubungi Kami
                </Link>
              </li>

              {/* <li className="flex items-center">
                <PagesDropdown />
              </li> */}
              {/* <li className="flex items-center">
                <a
                  className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="/"
                  target="_blank"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-facebook text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Share</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="/"
                  target="_blank"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-twitter text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Tweet</span>
                </a>
              </li>

              <li className="flex items-center">
                <a
                  className="lg:text-white lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="/"
                  target="_blank"
                >
                  <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-github text-lg leading-lg " />
                  <span className="lg:hidden inline-block ml-2">Star</span>
                </a>
            </li> */}

              {/* <li className="flex items-center">
                <Link
                  to="/member"
                  className="bg-gold text-white active:bg-gold text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                >
                   Masuk / Daftar
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>

      {
        visible ? <div 
          className="shadow hover:shadow-lg"
          style={{
            position: 'fixed',
            width: 50,
            right: 30,
            bottom: 30,
            height: 50,
            zIndex: 10,
            cursor: 'pointer',
            backgroundColor: '#e0bf4b',
            borderRadius: 25,
            color: 'white',
            textAlign: 'center',
          }} 
          onClick={() => scrollToTop()}
        ><i className="fas fa-arrow-up mt-2 text-xl"></i></div> : null
      }
    </>
  );
}
