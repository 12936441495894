import React, {useCallback} from "react";

// components

import CardUser from "components/Cards/CardUser.js";
import CardFormUser from "components/Cards/CardFormUser.js";

export default function User() {
  const [action, setAction] = React.useState('list');

  const handleSetAction = useCallback((e) => {
    setAction(e);
  }, []);

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          {
            action === 'list' ? <CardUser setAction={handleSetAction} /> : <CardFormUser setAction={handleSetAction} action={action} />
          }
        </div>
      </div>
    </>
  );
}
