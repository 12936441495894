import * as Constant from '../../helpers/Constant.js';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from "react";
import Skeleton from 'react-loading-skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios-jsonp-pro';

// components
export default function SectionContact() {
  const [abouts, setAbouts] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [messageError, setMessageError] = React.useState('');
  const [formValue, setFormValue] = React.useState({
    name: '',
    email: '',
    phone: '',
    messages: '',
  });
  const [formValidate, setFormValidate] = React.useState({
    validateName: false,
    validateEmail: false,
    validatePhone: false,
    validateMessage: false,
    name: '',
    email: '',
    phone: '',
    messages: ''
  });

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  }

  const handleHideToast = () => {
    setShowToast(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formValue.name === '') {
      setFormValidate({
        ...formValidate,
        validateName: true,
        name: 'Nama tidak boleh kosong!',
      });
      return false;
    }

    if (formValue.email === '') {
      setFormValidate({
        ...formValidate,
        validateEmail: true,
        email: 'Email tidak boleh kosong!',
      });
      return false;
    }

    if (formValue.phone === '') {
      setFormValidate({
        ...formValidate,
        validatePhone: true,
        phone: 'Nomor handphone tidak boleh kosong!',
      });
      return false;
    }

    if (formValue.messages === '') {
      setFormValidate({
        ...formValidate,
        validateMessage: true,
        messages: 'Pesan tidak boleh kosong!',
      });
      return false;
    }

    setIsLoadingSubmit(true);
    const bodyFormData = new FormData();
    bodyFormData.append('name', formValue.name);
    bodyFormData.append('email', formValue.email);
    bodyFormData.append('phone', formValue.phone);
    bodyFormData.append('messages', formValue.messages);
    
    return await axios.post(Constant.BASEURL + 'sendEmail', bodyFormData)
    .then(res => {
      // console.log(res);
      setShowToast(true);
      setIsLoadingSubmit(false);

      setFormValue({
        name: '',
        email: '',
        phone: '',
        messages: ''
      });

      setFormValidate({
        validateName: false,
        validateEmail: false,
        validatePhone: false,
        validateMessage: false
      });
    })
    .catch(error => {
      setIsLoadingSubmit(false);
      setShowAlert(true);
      setMessageError(error.message);
    });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    return await axios.get(Constant.BASEURL + 'about/1')
    .then(res => {
      setIsLoading(false);
      setAbouts(res.data.data.about);
    })
    .catch(error => {
      console.log(error.message);
    });
  }

  return (
    <>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={showToast}
          autoHideDuration={2000}
          onClose={handleHideToast}
          message={'Pesan berhasil dikirim!'}
        />
      </div>

      {showAlert ? (
        <Dialog
          open={showAlert}
          onClose={() => setShowAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
          <DialogContent style={{minWidth: 300}}>
            <DialogContentText id="alert-dialog-description">
              {messageError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowAlert(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      
      <section className="relative py-20 bg-white" id="contact-section">
        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <h3 className="text-3xl font-semibold">Datangi Kami</h3>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="office-building"
                          src={require("assets/img/icon/office_building.png").default} 
                          className="rounded-full mx-auto max-w-120-px pr-4"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          {isLoading ? <Skeleton height={15} count={2} /> : abouts.address}
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="clock"
                          src={require("assets/img/icon/clock.png").default} 
                          className="rounded-full mx-auto max-w-120-px pr-4"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          {isLoading ? <Skeleton height={15} count={1} /> : abouts.open_hours}
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="call-phone"
                          src={require("assets/img/icon/call_phone.png").default} 
                          className="rounded-full mx-auto max-w-120-px pr-4"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          {isLoading ? <Skeleton height={15} count={1} /> : abouts.phone}
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="email"
                          src={require("assets/img/icon/email.png").default} 
                          className="rounded-full mx-auto max-w-120-px pr-4"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          {/* {isLoading ? <Skeleton height={15} count={1} /> : abouts.email} */}
                          contact@sgk.co.id
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
                <br></br>

                { 
                  isLoading ? <Skeleton height={260} count={1} /> : <iframe title="maps" src={abouts.map} height="260" style={{border:0, width: '100%'}} allowFullScreen="" loading="lazy"></iframe>
                }

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
