import React, { useCallback }  from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios-jsonp-pro';
import PropTypes from "prop-types";
import * as Constant from '../../helpers/Constant.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Snackbar from '@material-ui/core/Snackbar';

// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";

// import DataTable, { createTheme } from 'react-data-table-component';

// createTheme('solarized', {
//   text: {
//     primary: '#647FA0',
//     secondary: '#2aa198',
//   },
//   background: {
//     default: '#ffffff',
//   },
//   context: {
//     background: '#cb4b16',
//     text: '#FFFFFF',
//   },
//   divider: {
//     default: '#F1F5F9',
//   },
//   action: {
//     button: 'rgba(0,0,0,.54)',
//     hover: 'rgba(0,0,0,.08)',
//     disabled: 'rgba(0,0,0,.12)',
//   },
// });

const data = [];
// const columns = [
//   {
//     name: 'NAME',
//     selector: 'name',
//     sortable: true,
//   },
//   {
//     name: 'USERNAME',
//     selector: 'username',
//     sortable: true,
//   },
//   {
//     name: 'PRIVILEGE',
//     selector: 'privilege',
//     sortable: true,
//   },
//   {
//     name: 'STATUS',
//     selector: 'status',
//     sortable: true,
//   },
//   {
//     name: '...',
//     selector: 'more',
//     sortable: true,
//     right: true,
//   },
// ];

export default function CartUser({ setAction, color }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);

  const handleShowDialog = useCallback((e) => {
    setShowDialog(e);
  }, []);

  const handleIsEdit = useCallback((e) => {
    setAction(e)
  }, [setAction]);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  const history = useHistory();
  const [users, setUsers] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      return await axios.get(Constant.URL + '/users', {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('token'),
        }
      })
      .then(res => {
        setIsLoading(false);
        setUsers(res.data.data.users);

        res.data.data.users.map((key, index) => {
          data.push({
            id: key.id,
            name: key.name,
            username: key.username,
            privilege: key.privilege,
            status: key.status,
            more: <TableDropdown setShowDialog={handleShowDialog} setIsEdit={handleIsEdit} />
          })
        });

        // console.log(data);
      })
      .catch(error => {
        setIsLoading(false);
        if (error.message === 'Request failed with status code 401') {
          localStorage.clear();
          history.push("/admin");
        }
      });
    }
    fetchData();
  }, [setAction, handleIsEdit, handleShowDialog, history]);

  
  // const state = { toggledClearRows: false };

  // const handleChange = (state) => {
  //   // You can use setState or dispatch with something like Redux so we can use the retrieved data
  //   console.log('Selected Rows: ', state.selectedRows);
  // };
  
  return (
    <>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          autoHideDuration={1500}
          onClose={handleClose}
          message="Success Delete"
        />
      </div>

      {showDialog ? (
        <>
          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure want to delete this customer?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
            <button
              onClick={() => setShowDialog(false)}
              className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Close
            </button>

            <button
              onClick={() => {
                setShowDialog(false);
                setOpen(true)
              }}
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Save Change
            </button>
            </DialogActions>
          </Dialog>
        </>
      ) : null};

      {
        // !isLoading ? (
        //   <DataTable
        //     title="User"
        //     columns={columns}
        //     data={data}
        //     // theme="solarized"
        //     pagination={true}
        //     onSelectedRowsChange={handleChange}
        //   />
        // ) : (<span>Test</span>)
      }
      

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex justify-between">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                User
              </h3>

              <button
                onClick={() => {
                  setAction('add');
                  // setIsEdit(false);
                  // setShowModal(true);
                }}
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Name
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Username
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Privilege
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Status
                </th>
                {/* <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Users
                </th> */}
                {/* <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Completion
                </th> */}
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  <>
                    <tr>
                      <td colSpan="6" className="text-center">
                        <br></br>
                        <i className="fas fa-spin fa-spinner"></i>
                        <br></br>
                        <br></br>
                      </td>
                    </tr>
                  </>
                ) : users.length < 1 ? (
                  <>
                    <tr>
                        <td colSpan="6" className="text-center border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <br></br>
                          <span className={
                            "ml-3 text-blueGray-600"
                          }>No data available!</span>
                          <br></br>
                          <br></br>
                        </td>
                      </tr>
                  </>
                ) : users.map(( key, index ) => {
                  return (
                    <tr>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                        <img
                          src={require("assets/img/team-3-800x800.jpg").default}
                          className="h-12 w-12 bg-white rounded-full border"
                          alt="..."
                        ></img>{" "}
                        <span
                          className={
                            "ml-3 font-bold " +
                            +(color === "light" ? "text-blueGray-600" : "text-white")
                          }
                        >
                          {key.name}
                        </span>
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {key.username}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {key.privilege}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <i className="fas fa-circle text-blue-500 mr-2"></i> {key.status === '1' ? 'active' : 'inavtive'} 
                      </td>
                      {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div className="flex">
                          <img
                            src={require("assets/img/team-1-800x800.jpg").default}
                            alt="..."
                            className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                          ></img>
                          <img
                            src={require("assets/img/team-2-800x800.jpg").default}
                            alt="..."
                            className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                          ></img>
                          <img
                            src={require("assets/img/team-3-800x800.jpg").default}
                            alt="..."
                            className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                          ></img>
                          <img
                            src={require("assets/img/team-4-470x470.png").default}
                            alt="..."
                            className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                          ></img>
                        </div>
                      </td> */}
                      {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div className="flex items-center">
                          <span className="mr-2">60%</span>
                          <div className="relative w-full">
                            <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">
                              <div
                                style={{ width: "60%" }}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td> */}
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                        <TableDropdown setShowDialog={handleShowDialog} setIsEdit={handleIsEdit} />
                      </td>
                    </tr>
                  );
                })
              }
                
              
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CartUser.defaultProps = {
  color: "light",
};

CartUser.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
