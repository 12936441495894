import { Link } from "react-router-dom";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import React from "react";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

// import axios from 'axios-jsonp-pro';
// import * as Constant from "../../helpers/Constant";
// import Skeleton from 'react-loading-skeleton';









const Accordion = withStyles({
  root: {
    backgroundColor: '#E2E8F0',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    borderRadius: 30,
    backgroundColor: 'rgba(255, 255, 255)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: 20,
    minHeight: 56,
    '&$expanded': {
      backgroundColor: '#e0bf4b',
      color: 'white',
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: '#E2E8F0',
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    flexBasis: '100%',
    flexShrink: 0,
    fontWeight: 'bold'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

// components
export default function SectionFaq() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const history = useHistory();
  // const handleOnClick = (e) => {
  //   localStorage.setItem('section', e);
  //   history.push('/');
  // };

  // const [isLoading, setIsLoading] = React.useState(false);
  // const [faqs, setFaqs] = React.useState([]);

  // React.useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   return await axios.get(Constant.BASEURL + 'faq')
  //   .then(res => {
  //     // console.log(res);
  //     setIsLoading(false);
  //     setFaqs(res.data.data.faq);
  //   })
  //   .catch(error => {
  //     console.log(error.message);
  //   });
  // }

  return (
    <>
      <section className="relative py-20 bg-blueGray-200" id="faq-section">

        <div className="container mx-auto px-4">

          <div className="flex flex-wrap justify-center text-center mb-24">
            <div className="w-full lg:w-12/12 px-4">
              {/* <img
                alt="term-and-condition"
                src={require("assets/img/icon/term_and_condition.png").default}
                style={{margin: 'auto'}}
              /> */}
              <h2 className="text-4xl font-semibold">Paling Sering Ditanyakan</h2>
            </div>
          </div>

          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 ml-auto mr-auto">
              <img
                alt="tnc-2"
                className="max-w-full rounded-lg shadow-lg"
                src={require("assets/img/tnc_2.png").default}
              />
            </div>
            <div className="w-full md:w-6/12 ml-auto mr-auto px-4 sm:mt-20">
              <div className="md:pr-12">

                <div className={classes.root}>
                  {/* {
                    faqs.map((key, index ) => {
                      const panel = 'panel' + index;

                      return (
                        <Accordion expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                          <AccordionSummary
                            expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="bg-gold"
                          >
                            <Typography className={classes.heading}>
                              {key.question}
                            </Typography> 
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              {key.answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })
                  } */}
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className="bg-gold"
                    >
                      <Typography className={classes.heading}>
                        Apakah pinjaman bisa di perpanjang?
                      </Typography> 
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Iya, perpanjangan dengan skema gadai ulang anda diharuskan untuk membayar sewa modal dan biaya administrasi
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography className={classes.heading}>
                        Apakah perpanjangan bisa dilakukan kurang dari 4 bulan?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Bisa, perpanjangan bisa dilakukan per 15 hari
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                    >
                      <Typography className={classes.heading}>
                        Jika pelanggan membayar lebih cepat apakah sewa modal akan dipotong?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Benar, penagihan sewa modal akan disesuaikan pula
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary
                      expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                      aria-controls="panel4bh-content"
                      id="panel4bh-header"
                    >
                      <Typography className={classes.heading}>
                        Bagaimana apabila Surat Bukti Gadai hilang?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Pelanggan dapat melengkapi Surat Keterangan Hilang dari kepolisian dan mengisi surat pernyataan
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary
                      expandIcon={<i className="fas fa-chevron-down text-gold" style={{fontSize: 15}}></i>}
                      aria-controls="panel5bh-content"
                      id="panel5bh-header"
                    >
                      <Typography className={classes.heading}>
                        Apakah perhiasan yang kurang lengkap, cacat, atau rusak dapat dijadikan sebagai barang jaminan?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Tidak akan diterima perhiasan yang kurang lengkap, rusak , atau cacat
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        </section>
        
        {/*
        <section className="pb-16 bg-white relative pt-32">

          <div className="container mx-auto">
            <div className="flex flex-wrap justify-center bg-white rounded-lg px-12 relative z-10">
              <div className="w-full text-center lg:w-8/12">
                <h3 className="font-semibold text-3xl">
                  Yuk, gadaikan emas Anda sekarang!
                </h3>
                <div className="sm:block flex flex-col mt-10">
                  <Link
                    to="/simulation/0"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-gold active:bg-gold uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Simulasi Gadai
                  </Link>
                </div>
                <div className="text-center mt-16"></div>
              </div>
            </div>
          </div>
        </section>
        */}
    </>
  );
}
