import React, {useCallback} from "react";

// components

import CardProduct from "components/Cards/CardProduct.js";
import CardFormProduct from "components/Cards/CardFormProduct.js";

export default function Product() {
  const [id, setId] = React.useState(null);
  const [action, setAction] = React.useState('list');

  const handleSetAction = useCallback((e) => {
    setAction(e);
  }, []);

  const handleSetId = useCallback((e) => {
    setId(e);
  }, []);

  // React.useEffect(() => {
  //   handleSetAction();
  //   handleSetId();
  // }, [handleSetAction, handleSetId]);

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          {
            action === 'list' ? <CardProduct setAction={handleSetAction} id={id} setId={handleSetId} /> : <CardFormProduct setAction={handleSetAction} action={action} id={id} />
          }
        </div>
      </div>
    </>
  );
}
