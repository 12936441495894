/*eslint-disable*/
import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

import News from '../components/Section/SectionNews';
import Contact from '../components/Section/SectionContact';
import Partner from '../components/Section/SectionPartner';
// import Product from '../components/Section/SectionProduct';
import About from '../components/Section/SectionAbout';
// import Content from '../components/Section/SectionContent';
import Home from '../components/Section/SectionHome';
import Advantages from '../components/Section/SectionAdvantages';
import Terms from '../components/Section/SectionTerms';
import Mechanism from '../components/Section/SectionMechanism';
import Faq from '../components/Section/SectionFaq';

export default function Index() {
  const section = localStorage.getItem('section');

  React.useEffect(() => {
    setTimeout(
      () => section !== null ? document.getElementById(section).scrollIntoView({ behavior: "smooth" }) : null, 
      1500
    );
  }, [section]);

  return (
    <>
      <IndexNavbar fixed />
      <Home />
      <Advantages />
      <Terms />
      <Mechanism />
      <Faq />
      {/* <Content />
      <Product /> */}
      {/* <News /> */}
      <About />
      <Contact />
      <Partner />
      <Footer />
    </>
  );
}
