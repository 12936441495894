import React from "react";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footers/Footer.js";

import SectionPay from '../components/Section/SectionPay';

export default function Pay() {

  return (
    <>
      <AuthNavbar fixed />
      <SectionPay />
      <Footer />
    </>
  );
}
