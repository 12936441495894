import React from "react";
// import axios from 'axios-jsonp-pro';
// import * as Constant from "../../helpers/Constant";
// import Skeleton from 'react-loading-skeleton';

// components

export default function SectionTerms() {
  // const [isLoading, setIsLoading] = React.useState(false);
  // const [abouts, setAbouts] = React.useState({});

  // React.useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   return await axios.get(Constant.BASEURL + 'about/1')
  //   .then(res => {
  //     setIsLoading(false);
  //     setAbouts(res.data.data.about);
  //   })
  //   .catch(error => {
  //     console.log(error.message);
  //   });
  // }

  return (
    <>
      <section className="relative py-20" id="terms-section">

        <div className="container mx-auto px-4">

          <div className="flex flex-wrap justify-center text-center mb-24">
            <div className="w-full lg:w-12/12 px-4">
              <h2 className="text-4xl font-semibold">Syarat Pengajuan Laku Gadai</h2>
            </div>
          </div>

          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 ml-auto mr-auto">
              <img
                alt="tnc"
                className="max-w-full rounded-lg shadow-lg"
                src={require("assets/img/tnc.png").default}
              />
            </div>
            <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="arrow-right"
                          src={require("assets/img/icon/arrow_right.png").default}
                          className="rounded-full mx-auto max-w-120-px"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Merupakan warga negara Indonesia dan memiliki Kartu Tanda Penduduk (KTP)
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="arrow-right"
                          src={require("assets/img/icon/arrow_right.png").default} 
                          className="rounded-full mx-auto max-w-120-px"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Telah berusia 17 tahun
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="arrow-right"
                          src={require("assets/img/icon/arrow_right.png").default} 
                          className="rounded-full mx-auto max-w-120-px"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Membawa barang jaminan dan dokumen yang menyertai seperti invoice dan sertifikat
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="arrow-right"
                          src={require("assets/img/icon/arrow_right.png").default} 
                          className="rounded-full mx-auto max-w-120-px"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Barang jaminan yang diterima adalah perhiasan emas mulai dari 16 karat keatas, perhiasan berlian dan logam mulia batangan.
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="arrow-right"
                          src={require("assets/img/icon/arrow_right.png").default} 
                          className="rounded-full mx-auto max-w-120-px"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Jangka waktu pinjaman adalah 120 hari dan dapat diperpanjang dengan skema gadai uang.
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="arrow-right"
                          src={require("assets/img/icon/arrow_right.png").default} 
                          className="rounded-full mx-auto max-w-120-px"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Biaya sewa modal adalah 6% per 120 hari
                        </h4>
                      </div>
                    </div>
                  </li>

                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <img
                          alt="arrow-right"
                          src={require("assets/img/icon/arrow_right.png").default} 
                          className="rounded-full mx-auto max-w-120-px"
                        />
                      </div>
                      <div>
                        <h4 className="text-blueGray-500">
                          Menyetujui syarat dan ketentuan yang berlaku
                        </h4>
                      </div>
                    </div>
                  </li>


                </ul>
              </div>
            </div>
          </div>
        </div>
        </section>
    </>
  );
}
