import React from 'react';

import Member from "layouts/Member.js";
import AuthMember from "layouts/AuthMember.js";

const AuthorizationContext  = React.createContext();

const RoutesMember = () => {
  const token = localStorage.getItem('tokenMember');
  // console.log('TOKEN ' + token); 
  return (
    <AuthorizationContext.Provider value={token}>
      {token !== null ? <Member /> : <AuthMember />}
    </AuthorizationContext.Provider>
  )
}

export default RoutesMember;