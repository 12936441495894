import React from "react";
import axios from 'axios-jsonp-pro';
import * as Constant from '../../helpers/Constant.js';

export default function Footer() {
  const [abouts, setAbouts] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      return await axios.get(Constant.BASEURL + 'about/1')
      .then(res => {
        setIsLoading(false);
        setAbouts(res.data.data.about);
      })
      .catch(error => {
        console.log(error.message);
      });
    }
    fetchData();
  }, []);
  
  return (
    <>
      <footer className="bg-black pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">

            <div className="w-full lg:w-3/12 px-4">
              <img
                alt="logo-sgk"
                src={require("assets/img/logo.png").default}
                style={{width: 80}}
                className="margin-auto"
              />
              <span className="block text-white text-sm font-semibold mb-2 mt-5">
                {abouts.name}
              </span>
              <h5 className="text-sm mt-0 mb-2 text-white">
                {/* Synthesis Square
                <br></br>
                Tower II Lt. 10
                <br></br>
                Jl. Gatot Subroto No. 177A
                <br></br>
                Menteng Dalam. Tebet
                <br></br>
                Jakarta Selatan 12870
                <br></br> */}
                {isLoading ? '' : abouts.address}
              </h5>
              {/* <div className="mt-6 lg:mb-0 mb-6">
                <button
                  className="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-twitter"></i>
                </button>
                <button
                  className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-facebook-square"></i>
                </button>
                <button
                  className="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-dribbble"></i>
                </button>
                <button
                  className="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                >
                  <i className="fab fa-github"></i>
                </button>
              </div> */}
            </div>

            <div className="w-full lg:w-3/12 px-4 ml-auto">
              <span className="block text-white text-sm font-semibold mb-2 mt-20">
                Jam Buka
              </span>
              <ul className="list-unstyled">
                <li>
                  <span
                    className="text-white hover:text-white block pb-2 text-sm"
                  >
                    Senin - Jumat
                  </span>
                </li>
                <li>
                  <span
                    className="text-white hover:text-white block pb-2 text-sm"
                  >
                    09:00 - 17:00
                  </span>
                </li>
                <li>
                  <span
                    className="text-white hover:text-white block pb-2 text-sm"
                  >
                    Sabtu - Minggu
                  </span>
                </li>
                <li>
                  <span
                    className="text-white hover:text-white block pb-2 text-sm"
                  >
                    Tutup
                  </span>
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-3/12 px-4">
              <span className="block text-white text-sm font-semibold mb-2 mt-20">
                Hubungi Kami
              </span>
              <ul className="list-unstyled">
                <li>
                  <a
                    className="text-white hover:text-gold block pb-2 text-sm"
                    href="tel:0218317788"
                  >
                    Tel: {abouts.phone}
                  </a>
                </li>
                <li>
                  <a
                    className="text-white hover:text-gold block pb-2 text-sm"
                    // href={"mailto:" + abouts.email}
                    href="mailto:contact@sgk.co.id"
                  >
                    {/* {abouts.email} */}
                    Email: contact@sgk.co.id
                  </a>
                </li>
              </ul>
            </div>

            <div className="w-full lg:w-3/12 px-4">
              {/* <span className="block text-white text-sm font-semibold mb-2 mt-20">
                Terdaftar dan Diawasi Oleh
              </span> */}
              <ul className="list-unstyled">
              <span className="block text-white text-sm font-semibold mb-2 mt-20">
               PT. Sentral Gadai Kencana
              </span>
              <span className="block text-white text-sm font-semibold mb-2 mt-0">
               BERIZIN RESMI DAN TERDAFTAR DI OTORITAS JASA KEUANGAN
              </span>
              <span className="block text-white text-sm font-semibold mb-2 mt-5">
               Nomor Izin KEP-46/NB.1/2021 Tanggal Juli 2021
              </span>
                <li>
                  {/* <img
                    alt="logo-ojk"
                    src={require("assets/img/ojk_update.png").default}
                    className="margin-auto"
                  /> */}
                </li>
              </ul>
            </div>

          </div>
          {/* <hr className="my-6 border-gray" /> */}
          {/* <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-white py-1">
                Copyright © {new Date().getFullYear()} PT Sentral Gadai Kencana 
              </div>
            </div>
          </div> */}
        </div>
      </footer>
    </>
  );
}
