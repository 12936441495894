import React from "react";
// import axios from 'axios-jsonp-pro';
// import * as Constant from "../../helpers/Constant";
// import Skeleton from 'react-loading-skeleton';

// components

export default function SectionAdvantages() {
  // const [isLoading, setIsLoading] = React.useState(false);
  // const [abouts, setAbouts] = React.useState({});

  // React.useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   return await axios.get(Constant.BASEURL + 'about/1')
  //   .then(res => {
  //     setIsLoading(false);
  //     setAbouts(res.data.data.about);
  //   })
  //   .catch(error => {
  //     console.log(error.message);
  //   });
  // }

  return (
    <>
      <section className="pb-20 pt-12 bg-blueGray-200" id="advantages-section">
        <div className="container mx-auto px-4">

          <div className="flex flex-wrap justify-center text-center mb-12">
            <div className="w-full lg:w-12/12 px-4">
              <h2 className="text-4xl font-semibold">Kelebihan Laku Gadai</h2>
            </div>
          </div>

          <div className="flex flex-wrap items-center mt-12">
            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">

              <div className="w-full md:w-12/12 px-4">
                <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex">
                    <div className="md:w-2/12">
                      <img
                        alt="fast-clock"
                        src={require("assets/img/icon/fast_clock.png").default}
                      />
                    </div>
                    <div className="md:w-10/12 px-4">
                      <h6 className="text-xl font-semibold">Proses Mudah dan Cepat</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Cukup membawa KTP dan tidak ada penilaian kredit
                        <br></br>
                        <br></br>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-12/12 px-4">
                <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex">
                    <div className="md:w-2/12">
                      <img
                        alt="thumb-up"
                        src={require("assets/img/icon/thumb_up.png").default}
                      />
                    </div>
                    <div className="md:w-10/12 px-4">
                      <h6 className="text-xl font-semibold">Layanan Terbaik</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Pelayanan prima yang mengutamakan kepuasan pelanggan
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-12/12 px-4">
                <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex">
                    <div className="md:w-2/12">
                      <img
                        alt="shield-check"
                        src={require("assets/img/icon/shield_check.png").default}
                        style={{width: 70}}
                      />
                    </div>
                    <div className="md:w-10/12 px-4">
                      <h6 className="text-xl font-semibold">Keamanan Terjamin</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Barang jaminan Anda disimpan dengan aman dan telah diasuransikan
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>

            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">
              
              <div className="w-full md:w-12/12 px-4">
                <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex">
                    <div className="md:w-2/12">
                      <img
                        alt="discount-bag"
                        src={require("assets/img/icon/discount_bag.png").default}
                        style={{width: 70}}
                      />
                    </div>
                    <div className="md:w-10/12 px-4">
                      <h6 className="text-xl font-semibold">Nilai Pinjaman yang Besar</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Kami akan berikan nilai pinjaman yang besar untuk emas dan perhiasan Anda
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-12/12 px-4">
                <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex">
                    <div className="md:w-2/12">
                      <img
                        alt="interest-rate"
                        src={require("assets/img/icon/interest_rate.png").default}
                        style={{width: 70}}
                      />
                    </div>
                    <div className="md:w-10/12 px-4">
                      <h6 className="text-xl font-semibold">Biaya Sewa Modal Terjangkau</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Cukup membayar sewa modal per 15 hari dan tidak dikenakan biaya lain
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-12/12 px-4">
                <div className="flex min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex">
                    <div className="md:w-2/12">
                      <img
                        alt="open-calendar"
                        src={require("assets/img/icon/open_calendar.png").default}
                        style={{width: 70}}
                      />
                    </div>
                    <div className="md:w-10/12 px-4">
                      <h6 className="text-xl font-semibold">Jangka Waktu Pinjaman yang Fleksibel</h6>
                      <p className="mt-2 mb-4 text-blueGray-500">
                        Pilihan tenor yang beragam dan fleksibel menyesuaikan dengan kebutuhan Anda
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
}
