import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import About from "views/About.js";
import ButikLakuemas from "views/ButikLakuemas.js";
import Contact from "views/Contact.js";
import GoldTunai from "views/GoldTunai.js";
import Index from "views/Index.js";
import LakuSimpan from "views/LakuSimpan.js";
import Pay from "views/Pay.js";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "routes/Routes.js";
import RoutesMember from "routes/RoutesMember.js";
import Simulation from "views/Simulation.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      {/* <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} /> */}

      <Route path="/admin" component={Routes}/>
      <Route path="/member" component={RoutesMember}/>

      {/* add routes without layouts */}
      <Route path="/about" exact component={About} />
      <Route path="/gold-tunai" exact component={GoldTunai} />
      <Route path="/laku-simpan" exact component={LakuSimpan} />
      <Route path="/butik-lakuemas" exact component={ButikLakuemas} />
      {/* <Route path="/simulation/:id" exact component={Simulation} /> */}
      <Route path="/contact" exact component={Contact} />

      <Route path="/pay/:id" exact component={Pay} />

      {/* <Route path="/landing" exact component={Landing} />
      <Route path="/profile" exact component={Profile} /> */}
      <Route path="/" exact component={Index} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
