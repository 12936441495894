import React from "react";
import axios from 'axios-jsonp-pro';
import * as Constant from "../../helpers/Constant";
import Skeleton from 'react-loading-skeleton';

// components

export default function SectionAbout() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [abouts, setAbouts] = React.useState({});

  React.useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    return await axios.get(Constant.BASEURL + 'about/1')
    .then(res => {
      setIsLoading(false);
      setAbouts(res.data.data.about);
    })
    .catch(error => {
      console.log(error.message);
    });
  }
  return (
    <>
      <section className="relative py-20" id="about-section">
        <div className="container mx-auto px-4">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 ml-auto">
              <img
                alt="tentang-kami"
                // className="max-w-full rounded-lg shadow-lg"
                src={require("assets/img/tentang_kami_2.png").default}
              />
            </div>
            <div className="w-full md:w-6/12 ml-auto mr-auto">
              <div className="md:pr-12">
                <h3 className="text-3xl font-semibold text-black">
                  Tentang Kami
                </h3>
                <br></br>
                <div style={{border: '20px solid #eeeeee', borderLeft: 'none', padding: 20}}>
                  <p className="mt-4 text-lg leading-relaxed text-black">
                    <h3 className="text-3xl font-semibold" style={{fontFamily: 'Raleway'}}>
                      PT SENTRAL <br></br>GADAI KENCANA
                    </h3>
                    <br></br> 
                    {isLoading ? <Skeleton height={15} count={7} /> : abouts.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
