import React, {useCallback} from "react";

// components

import CardDiamond from "components/Cards/CardDiamond.js";
import CardFormDiamond from "components/Cards/CardFormDiamond.js";

export default function Diamond() {
  const [id, setId] = React.useState(null);
  const [action, setAction] = React.useState('list');

  const handleSetAction = useCallback((e) => {
    setAction(e);
  }, []);

  const handleSetId = useCallback((e) => {
    setId(e);
  }, []);

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          {
            action === 'list' ? <CardDiamond setAction={handleSetAction} id={id} setId={handleSetId} /> : <CardFormDiamond setAction={handleSetAction} action={action} id={id} />
          }
        </div>
      </div>
    </>
  );
}
