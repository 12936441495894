import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components
import MobileNavbar from "components/Navbars/MobileNavbar.js";

// views
import Dashboard from "views/member/Dashboard.js";
import Notification from "views/member/Notification.js";
import Profile from "views/member/Profile.js";
import Detail from "views/member/Detail.js";

export default function Member() {
  return (
    <>
      <main>
        <section className="w-full h-full bg-blueGray-200">
          <div className="container-mobile mx-auto h-full h-auto bg-blueGray-200 shadow-lg bg-center bg-cover" style={{minHeight: 800}}>
            <MobileNavbar />
            <Switch>
              <Route path="/member/dashboard" exact component={Dashboard} />
              <Route path="/member/notification" exact component={Notification} />
              <Route path="/member/profile" exact component={Profile} />
              <Route path="/member/detail/:id" exact component={Detail} />

              <Redirect from="/member" to="/member/dashboard" />
            </Switch>
          </div>
        </section>
      </main>
    </>
  );
}
