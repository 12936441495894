// LOCAL //
// export const BASEURL = 'http://localhost:8000/';
// export const URL = 'http://localhost:8000/api';

// PREPRODUCTION //
// export const BASEURL = 'https://test-api.sgk.co.id/';
// export const URL = 'https://test-api.sgk.co.id/api';
// export const APIPOS_LAKUEMAS = 'https://test-pos.lakuemas.com/api';

// PRODUCTION //
export const BASEURL = 'https://api.sgk.co.id/';
export const URL = 'https://api.sgk.co.id/api';
export const APIPOS_LAKUEMAS = 'https://butik.lakuemas.com/api';

// export const APIEXT_LAKUEMAS = 'https://test-apiext.lakuemas.com/';