import React, { useCallback }  from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios-jsonp-pro';
import PropTypes from "prop-types";
import * as Constant from '../../helpers/Constant.js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

import Snackbar from '@material-ui/core/Snackbar';

// components
import TableDropdown from "components/Dropdowns/TableDropdown.js";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#FFB200',
        borderColor: '#FFB200',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function CardContent({ setAction, id, setId, color }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState('');
  const [showDialog, setShowDialog] = React.useState(false);
  const [refreshKey, setRefreshKey] = React.useState(0);

  const handleHideToast = () => {
    setShowToast(false);
  };

  const handleSetId = useCallback((e) => {
    setId(e);
  }, [setId]);

  const handleShowDialog = useCallback((e) => {
    setShowDialog(e);
  }, []);

  const handleIsEdit = useCallback((e) => {
    setAction(e)
  }, [setAction]);

  const handleChangePosition = (e) => {
    console.log(e);
  }
  
  const history = useHistory();
  const [contents, setContents] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      return await axios.get(Constant.URL + '/content/index', {
        headers: {
          'Authorization': 'bearer ' + localStorage.getItem('token'),
        }
      })
      .then(res => {
        setIsLoading(false);
        setContents(res.data.data.content); 
      })
      .catch(error => {
        setIsLoading(false);
        if (error.message === 'Request failed with status code 401') {
          localStorage.clear();
          history.push("/admin");
        }
      });
    }
    fetchData();
  }, [refreshKey, history]);

  const handleDestroy = async () => {
    return await axios.delete(Constant.URL + '/content/destroy/' + id, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('token'),
      }
    })
    .then(res => {
      console.log(res.data.data);
      setToastMessage('Data deleted')
      setShowToast(true);
      // fetchData();
      setRefreshKey(oldKey => oldKey +1);
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        localStorage.clear();
        history.push("/admin");
      }
    });
  }
  
  const handlePublish = async (id, publish) => {
    const publ = publish === 0 ? '1' : '0';
    const bodyFormData = new FormData();
    bodyFormData.append('id', id);
    bodyFormData.append('publish', publ);

    return await axios.post(Constant.URL + '/content/publish', bodyFormData, {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('token')
      }
    })
    .then(res => {
      // const message = publish === 0 ? 'Data published' : 'Data not published';
      setToastMessage(publ === 0 ? 'Data not published' : 'Data published');
      setShowToast(true);
      // fetchData();
    })
    .catch(error => {
      if (error.message === 'Request failed with status code 401') {
        localStorage.clear();
        history.push("/admin");
      }
    });

    // setShowToast(true);
  }
  
  return (
    <>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={showToast}
          autoHideDuration={1500}
          onClose={handleHideToast}
          message={toastMessage}
        />
      </div>

      {showDialog ? (
        <>
          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure want to delete this content?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                onClick={() => setShowDialog(false)}
                className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                No
              </button>

              <button
                onClick={() => {
                  handleDestroy();
                  setShowDialog(false);
                }}
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Yes
              </button>
            </DialogActions>
          </Dialog>
        </>
      ) : null};

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex justify-between">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Content
              </h3>

              <button
                onClick={() => {
                  setAction('add');
                }}
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Title
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Subtitle
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Description
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Image
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Image Position
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Publish
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  <>
                    <tr>
                      <td colSpan="6" className="text-center">
                        <br></br>
                        <i className="fas fa-spin fa-spinner"></i>
                        <br></br>
                        <br></br>
                      </td>
                    </tr>
                  </>
                ) : contents.length < 1 ? (
                  <>
                    <tr>
                        <td colSpan="6" className="text-center border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <br></br>
                          <span className={
                            "ml-3 text-blueGray-600"
                          }>No data available!</span>
                          <br></br>
                          <br></br>
                        </td>
                      </tr>
                  </>
                ) : contents.map((key, index ) => {
                  return (
                    <tr key={index}>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <span
                          className={
                            "ml-3 font-bold text-blueGray-600"
                          }
                        >
                          {key.title}
                      </span>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {key.subtitle.substring(0, 50)}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {key.description.substring(0, 50)}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <div className="flex">
                          <img
                            src={key.image === '' ? require("assets/img/image-not-found.png").default : Constant.BASEURL + key.image}
                            alt="..."
                            className="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                          ></img>
                        </div>
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <Grid component="label" container alignItems="center" spacing={1}>
                          <Grid item>Left</Grid>
                          <Grid item>
                            <AntSwitch checked={key.position === 'left' ? false : true} onChange={(e) => handleChangePosition(e)} name="checked" />
                          </Grid>
                          <Grid item>Right</Grid>
                        </Grid>
                      </td>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          <input
                            type="checkbox"
                            className="bg-gold form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                            defaultChecked={key.publish}
                            onChange={() => handlePublish(key.id, key.publish)}
                          />
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                        <TableDropdown id={key.id} setId={handleSetId} setShowDialog={handleShowDialog} setIsEdit={handleIsEdit} />
                      </td>
                    </tr>
                  );
                })
              }
                
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardContent.defaultProps = {
  color: "light",
};

CardContent.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
